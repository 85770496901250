import React from 'react';
import axios from 'axios';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput
}
from 'mdb-react-ui-kit';

const Login=()=> {
    const [formData, setFormData ]=useState({
        email:'',
        password:'',
    })

    const handleChange =(e)=>{
        const { name,  value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
          }));     
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
          const name = formData.name
          await axios.post('', formData);
    
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer);
              toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: 'success',
            text: name,
            title: 'Submited successfully Thank you',
          });
          
        } catch (error) {
          console.error('Error submitting form:', error);
        }
      };
  return (
    <MDBContainer className="my-5 gradient-form">

      <MDBRow>

      <MDBCol col='6' className="mb-5">
          <div className="d-flex flex-column  justify-content-center gradient-custom-2 h-100 mb-4">
          <div className="text-center">
              <img className='loginpgimg' src={'https://www.sdmipl.com/assets/images/sionlogo.png'}
               alt="logo" />
            </div>
            <div className="text-black px-3 py-4 p-md-5 mx-md-4">
              <h4 className="mb-4">We are more than just a company</h4>
              <p className="small mb-0">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>

          </div>

        </MDBCol>

        <MDBCol col='6' className="mb-5  shadow-md">
          <div className="d-flex flex-column ms-5">

            <div className="text-center">
               
              <h4 className="mt-1 mb-5 pb-1 text-danger"><span className='text-primary'>SDMIPL</span> Admin Login</h4>
            </div>

            <p className='text-primary'>Please login to your account</p>

            <MDBInput wrapperclassName='mb-4' label='Email address' onChange={handleChange} name='email' type='email'/>
            <MDBInput wrapperclassName='mb-4' label='Password' onChange={handleChange} id='form2' name='password' type='password'/>

            <div className="text-center pt-1 mb-5 pb-1">
              <MDBBtn className="mb-4 w-100 gradient-custom-2"onClick={handleSubmit}>Login in</MDBBtn>
              <Link className="text-muted float-right swal2-popup" to="#!">Forgot password?</Link>
            </div>

          </div>

        </MDBCol>

      </MDBRow>

    </MDBContainer>
  );
}

export default Login;