import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { IoCall } from "react-icons/io5";
import { MdAttachEmail } from "react-icons/md";
import { PiArrowFatLinesRightDuotone } from "react-icons/pi";
import { MdOutlineDoubleArrow } from "react-icons/md";
import { MdHome } from "react-icons/md";
import axios from 'axios';
import Swal from "sweetalert2";
import Servicequotation from '../component/servicequotation';

const Footer = () => {
  const [menuData, setMenuData] = useState([]);
  const [submenuData, setSubmenuData] = useState([]);
  const [blogsData, setBlogsData] = useState([]);
  const [services, setService] = useState([]);
  const [newsLetter, setNewsLetter] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const response = await fetch('https://www.sdmipl.com/portal/api/menu-list');
        const data = await response.json();
        if (data.menu) {
          setMenuData(data.menu);
          let sub = data.menu.map(item => item.submenu);
          let submenu = sub[1].map(item => (item));
          setSubmenuData(submenu);
        } else {
          console.error('No menu data available');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchMenuData();
  }, []);

  useEffect(() => {
    const blogs = async () => {
      try {
        const response = await axios.get('https://www.sdmipl.com/portal/api/blogs');
        if (response.status === 200) {
          setBlogsData(response.data.blogs);
        } else {
          console.error('Failed to fetch blogs data');
        }
      }
      catch (error) {
        console.log('something is wrong', error);
      }
    }
    blogs();
  }, []);
  useEffect(() => {
    const service = async () => {
      try {
        const res = await axios.get(`https://sdmipl.com/portal/api/view-service-card`);
        if (res.status === 200) {
          setService(res.data.result);
        } else {
          console.error('Failed to fetch blogs data');
        }
      } catch (error) {
        console.error('error fetching', error);
      }
    }
    service();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewsLetter((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  useEffect(() => { })
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const name = setNewsLetter.newsletter_email;
      await axios.post(
        "https://sdmipl.com/portal/api/newsletter-add",
        newsLetter
      );
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        text: name,
        title: "Thank You for Subscribing ! Congratulations! You're now part of our community. 🌟 Expect exciting updates and special offers straight to your inbox. Stay tuned!",
      });
      navigate("/");
      setNewsLetter('');
    } catch (error) {
      console.error("Error Submitting Mail:", error);
    }
  };
  return (
    <>
      {/* new footer */}
      <div>
        <Servicequotation />
      </div>
      <footer className='new_sion_footer mt-5'>
        <div className='container'>
          <div className='nsf_top'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='new_contact_sec'>
                <span><IoCall /> +91 78801 36882</span>
                <span><MdAttachEmail /> namaste@sdmipl.com </span>
                <span className='nsf_social_media'>
                  <Link aria-label='details sion links' to="https://www.facebook.com/siondatamaticsindia" title="facebook">
                    <i className="fa fa-facebook-square" area-hidden="true" target="_blank" ></i>
                  </Link>
                  <Link aria-label='details sion links' to="https://www.instagram.com/siondatamaticsindia/" title="instagram">
                    <i className="fa fa-instagram" area-hidden="true" target="_blank" ></i>
                  </Link>
                  <Link aria-label='details sion links' to="https://twitter.com/siondatamatics" title="twitter">
                    <i className="fa fa-twitter-square" area-hidden="true" target="_blank" ></i>
                  </Link>
                  <Link aria-label='details sion links' to="https://www.threads.net/@siondatamaticsindia/" title="threads">
                    <i className="fa-brands fa-threads" area-hidden="true" target="_blank"></i>
                  </Link>
                  <Link aria-label='details sion links' to="https://www.youtube.com/@SionDatamaticsindia" title="youtube">
                    <i className="fa fa-youtube-square" area-hidden="true" target="_blank" ></i>
                  </Link>
                  <Link aria-label='details sion links' to="https://www.linkedin.com/in/sion-datamatics-india-private-limited/" title="linkedin">
                    <i className="fa fa-linkedin-square" area-hidden="true" target="_blank" ></i>
                  </Link></span>
              </div>
            </div>
          </div>
          </div>
         
          <div className='nsf_middle pt-5'>
            <div className='row'>
              <div className='col-lg-3'>
                <div className='logo_img'>
                  <img
                    src="https://www.sdmipl.com/assets/images/sionlogo.png"
                    alt="Sion-DataMatics"
                    title="Best IT Company in Indore Madhya Pradesh India"
                    className="img-fluid"
                  />
                </div>
                {/* <h5 className='nsf_head'>About Us</h5> */}
                <p className="footer_about mt-4">We aim to serve valuable clients with the assistance of a bunch of passionate minds. With a talented team of experts and an obsession for pushing boundaries, we have established ourselves as trailblazers in the IT industry..</p>
                <p className='address'><MdHome />
                  11, 5, Nath Mandir Rd, in front of Arora Bhawan, South Tukoganj, Indore, Madhya Pradesh 452001</p>
              </div>
              <div className='col-lg-3'>
                <h5 className='nsf_head'>Quick Links</h5>
                <div className='nfs_items'>
                  <p><Link to={`/`}><MdOutlineDoubleArrow className='me-2' /> Home </Link></p>
                  {menuData && menuData.map((menuItem, index) => (
                    <p key={menuItem.id || index}>
                      <Link aria-label='details sion links' title={menuItem.title || ""} to={`/${menuItem.slug}`}>
                        <MdOutlineDoubleArrow className='me-2' /> {menuItem.title}
                      </Link>
                    </p>
                  ))}
                  <p><Link to={`/Services`}><MdOutlineDoubleArrow className='me-2' /> Services </Link></p>
                  <p><Link to={`/our-team`}><MdOutlineDoubleArrow className='me-2' /> Team </Link></p>
                  <p><Link to={`/contact-us`}><MdOutlineDoubleArrow className='me-2' /> Contact Us </Link></p>
                </div>
                <form className="form-subscribe mt-5 " onSubmit={handleSubmit}>
                  <div className="input-group d-flex align-items-center">
                    <input type="email" className="form-control input-lg" name="newsletter_email"
                      onChange={handleChange} placeholder="Your Eamil address" required />
                    <span className="input-group-btn">
                      <button className="btn btn-danger btn-lg" type="submit" >Subscribe</button>
                    </span>
                  </div>
                </form>
              </div>
              <div className='col-lg-3'>
                <h5 className='nsf_head'> Services</h5>
                <div className='nfs_items'>
                  {services && services.map((item) => (
                    <p key={item.id}>
                      <Link aria-label='details sion links' to={`/service/${item.slug}`} title={item.service_card_title} >
                        <MdOutlineDoubleArrow  className='me-2' />{item.service_card_title}</Link></p>
                  ))}
                  {submenuData && submenuData.map((menuItem, index) => (
                    <p key={menuItem.id || index}>
                      <Link aria-label='details sion links' title={menuData.title} to={`/${menuItem.slug}`}>
                        <MdOutlineDoubleArrow  className='me-2' />{menuItem.title}</Link></p>
                  ))}
                </div>
              </div>
              <div className='col-lg-3'>
                <h6 className='nsf_head'>Latest Blogs</h6>
                <div className='nfs_items'>
                  <ul>
                    {blogsData && blogsData.slice(0, 6).map((item) => (
                      <li key={item.id}>
                        <Link to={`/blog/${item.slug}`} title={item.title} >
                          <span><PiArrowFatLinesRightDuotone className='me-2' /></span>
                          <span className='fblog_p'>{item.title}</span>
                        </Link></li>
                    ))}</ul>
                </div>
              </div>
            </div>
          </div>
          <div className='nsf_bottom mt-5'>
            <p>    &copy; Copyright 1995: Sion Data Matics Pvt. Ltd. All rights reserved.</p>
            <div className="pp_sec">
              <Link aria-label='details sion links' to="/privacy-policy" title='Privacy Policy' className="pr-3">Privacy Policy</Link>
              <Link aria-label='details sion links' to="/terms-and-conditions" title='Terms & Conditions '>Terms &amp; Conditions</Link>
            </div>
          </div>
        </div>
      </footer>
    </>

  )
}

export default Footer;
