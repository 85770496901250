import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Modal from "react-modal";
import { BsPersonCircle } from 'react-icons/bs';
import { IoIosMail } from 'react-icons/io';
import { LuPhoneCall } from 'react-icons/lu';
import { FaLaptopCode } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import { MdAttachMoney } from "react-icons/md";
import quotation from '../../assets/images/quote.jpg'
import { useLocation } from "react-router-dom";
const Servicequotation = ({ closeModal }) => {
    const [Submenu, setSubmenu] = useState([]);
    const [image, setImage] = useState(null);

    const url_location = useLocation();
    const path = url_location.pathname.split('/');
    const lastSlug = path[path.length - 1];
    const formatSlug = (slug) => {
        if (!slug) return '';
        return slug
            .split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    // Format the last slug
    const formattedLastSlug = formatSlug(lastSlug);

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        mobile: "",
        project_type: "",
        services: formattedLastSlug || "",
        project_description: "",
        file_upload: null,
        image_upload: image,
    });
    const [showCustomInput, setShowCustomInput] = useState('');
    const subtitle = useRef(null);
    const [modalIsOpen, setIsOpen] = useState(false);
    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        if (subtitle.current) {
            subtitle.current.style.color = "black";
        }
    }

    function closeModal() {
        setIsOpen(false);
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        if (name === "project_type" && value === "more") {
            setShowCustomInput(!showCustomInput);
        }
    };

    const handleImagefile = (e) => {
        const file = e.target.files[0];
        setFormData((prevData) => ({
            ...prevData,
            file_upload: file,
        }));
    };
    const customStyles = {
        overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            zIndex: 1000,
        },
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            height: '95vh',
            transform: "translate(-50%, -50%)",
            zIndex: 1100,
        },
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const formDataForSubmit = new FormData();
            formDataForSubmit.append("name", formData.name);
            formDataForSubmit.append("email", formData.email);
            formDataForSubmit.append("mobile", formData.mobile);
            formDataForSubmit.append("project_type", formData.project_type);
            formDataForSubmit.append("services", formData.services);
            formDataForSubmit.append(
                "project_description",
                formData.project_description
            );
            formDataForSubmit.append("file_upload", formData.file_upload);
            formDataForSubmit.append("image_upload", image);

            const res = await axios.post(
                "https://www.sdmipl.com/portal/api/projectlead-create",
                formDataForSubmit
            );
            console.log("data", res);

            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", Swal.stopTimer);
                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
            });
            Toast.fire({
                icon: "success",
                text: formData.name,
                title: "Submitted Successfully. Thank you!",
            });
            closeModal();
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    useEffect(() => {
        const fetchMenuData = async () => {
            try {
                const response = await fetch(
                    "https://www.sdmipl.com/portal/api/services-list"
                );
                const data = await response.json();

                const services = data.service;
                setSubmenu(services);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchMenuData();
    }, []);

    return (
        <>
            <img className="qutionicn img-fluid" alt="Get a Quote" title="Get a Quote" src={quotation} onClick={openModal} />

            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Modal">
                <div className="col-lg-12 col-md-12 col-12">
                    <div className="serviceQform">
                        <form
                            onSubmit={handleSubmit}
                            encType="multipart/form-data"
                            method="post">
                            <fieldset className="">
                                <div className="text-muted fw-normal modalformheading">
                                    Service Request Enquiry
                                </div>
                                <button
                                    onClick={closeModal}
                                    className="closebtn btn btn-outline-danger text-lg btn-lg close-button float-right">
                                    &times;
                                </button>
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="name" className="form-label">
                                            Name :
                                        </label>
                                        <div className="input-group input-group-merge">
                                            <div className="input-icon landingpage">
                                                <BsPersonCircle />
                                            </div>
                                            <input
                                                type="text"
                                                onChange={handleChange}
                                                id="name"
                                                name="name"
                                                placeholder="Enter Your Name"
                                                className="form-control"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="email" className="form-label">
                                            Email :
                                        </label>
                                        <div className="input-group input-group-merge">
                                            <div className="input-icon landingpage">
                                                <IoIosMail />
                                            </div>
                                            <input
                                                type="email"
                                                id="email"
                                                onChange={handleChange}
                                                placeholder="mail@gmail.com"
                                                name="email"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="mobile" className="form-label">
                                            Mobile :
                                        </label>
                                        <div className="input-group input-group-merge">
                                            <div className="input-icon landingpage">
                                                <LuPhoneCall />
                                            </div>
                                            <input
                                                type="text" 
                                                onChange={handleChange}
                                                id="mobile"
                                                name="mobile"
                                                className="form-control"
                                                placeholder="+91 XXXXXXXXXX"
                                                maxLength="10" 
                                                pattern="\d{10}" 
                                                required
                                                onInput={(e) => e.target.value = e.target.value.replace(/\D/g, '')} 
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="form-group col-md-6">
                                    <label htmlFor="location" className="form-label">
                                        Location :
                                    </label>
                                    <div className="input-group input-group-merge">
                                        <div className="input-icon landingpage">
                                            <FaLocationDot />
                                        </div>
                                        <input
                                            type="text"
                                            onChange={handleChange}
                                            id="Location"
                                            name="location"
                                            placeholder="Enter your Location"
                                            className="form-control"
                                        />
                                    </div>
                                </div> */}
                                    <div className="form-group col-md-6">
                                        <label>Select a Service :</label>
                                        <div className="input-group input-group-merge">
                                            <div className="input-icon landingpage">
                                                <FaLaptopCode />
                                            </div>
                                            <select
                                                className="form-control form-select "
                                                onChange={handleChange}

                                                name="services"
                                                aria-label="Default select example"
                                                value={formData.services}
                                            >
                                                {Submenu ? (
                                                    <>
                                                        <option key="default" >
                                                            Select Services
                                                        </option>
                                                        {Submenu.map((value) => (
                                                            <option key={value.title} value={value.title}>
                                                                {value.title}
                                                            </option>
                                                        ))}
                                                    </>
                                                ) : null}
                                            </select>
                                        </div>
                                    </div>
                                    {formData.services === 'Mobile App Development' && (
                                        <>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="project" className="form-label">
                                                    Project Type :
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <div className="input-icon landingpage">
                                                        <AiOutlineFundProjectionScreen />
                                                    </div>
                                                    <select
                                                        id="project_type"
                                                        name="project_type"
                                                        onChange={handleChange}
                                                        className="form-control"
                                                    >
                                                        <option value="" disabled selected>Project Type</option>
                                                        <option value='law'>Law</option>
                                                        <option value='health'>Health Care App</option>
                                                        <option value="Educational">Educational App</option>
                                                        <option value="E-Commerce">E-Commerce App</option>
                                                        <option value='Quick-Commerce'>Quick-Commerce App</option>
                                                        <option value="more" >More</option>
                                                    </select>
                                                </div>
                                                {showCustomInput && (
                                                    <input
                                                        type="text"
                                                        name="project_type"
                                                        placeholder="Enter your project type"
                                                        className="form-control mt-2"
                                                        onChange={handleChange}

                                                    />
                                                )}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="hosting" className="form-label">
                                                    Domain & Hosting Purchased :
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <div className="input-icon landingpage">
                                                        <AiOutlineFundProjectionScreen />
                                                    </div>
                                                    <select
                                                        id="hosting"
                                                        name="hosting"
                                                        onChange={handleChange}
                                                        className="form-control"
                                                    >
                                                        <option value="" disabled selected>Domain Hosting</option>
                                                        <option value="yes">yes</option>
                                                        <option value="no">No</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="project" className="form-label">
                                                    Number of Pages ? :
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <div className="input-icon landingpage">
                                                        <AiOutlineFundProjectionScreen />
                                                    </div>
                                                    <select
                                                        id="project_pages"
                                                        name="project_pages"
                                                        onChange={handleChange}
                                                        className="form-control"
                                                    >
                                                        <option value="" disabled selected>Select number of pages</option>
                                                        <option value='5'>5</option>
                                                        <option value="10">10</option>
                                                        <option value="15">15</option>
                                                        <option value="more">More</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {formData.services === 'Digital Marketing' && (
                                        <>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="project" className="form-label">
                                                    Target to  Location :
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <div className="input-icon landingpage">
                                                        <FaLocationDot />
                                                    </div>
                                                    <input
                                                        type="text"
                                                        onChange={handleChange}
                                                        id="Location"
                                                        name="location"
                                                        placeholder="Enter your Targeted Location"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="monthly_budget" className="form-label">
                                                    Monthly Budget :
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <div className="input-icon landingpage">
                                                        <MdAttachMoney />
                                                    </div>
                                                    <input
                                                        type="text"
                                                        onChange={handleChange}
                                                        id="monthly_budget"
                                                        name="monthly_budget"
                                                        placeholder="Monthly_budget"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="website_url" className="form-label">
                                                    Website url :
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <div className="input-icon landingpage">
                                                        <MdAttachMoney />
                                                    </div>
                                                    <input
                                                        type="text"
                                                        onChange={handleChange}
                                                        id="project"
                                                        name="website_url"
                                                        placeholder="website_url"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {formData.services === 'Website Designing & Development' && (<>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="project" className="form-label">
                                                Project Type :
                                            </label>
                                            <div className="input-group input-group-merge">
                                                <div className="input-icon landingpage">
                                                    <AiOutlineFundProjectionScreen />
                                                </div>
                                                <select
                                                    id="project_type"
                                                    name="project_type"
                                                    onChange={handleChange}
                                                    className="form-control"
                                                >
                                                    <option value="" disabled selected>Project Type</option>
                                                    <option value='law'>Law</option>
                                                    <option value='health'>Health</option>
                                                    <option value="Educational">Educational</option>
                                                    <option value="E-Commerce">E-Commerce</option>
                                                    <option value='Quick-Commerce'>Quick-Commerce</option>
                                                    <option value="more" >More</option>
                                                </select>
                                            </div>
                                            {showCustomInput && (
                                                <input
                                                    type="text"
                                                    name="project_type"
                                                    placeholder="Enter your project type"
                                                    className="form-control mt-2"
                                                    onChange={handleChange}
                                                />
                                            )}
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="project" className="form-label">
                                                Number of Pages of Website ? :
                                            </label>
                                            <div className="input-group input-group-merge">
                                                <div className="input-icon landingpage">
                                                    <AiOutlineFundProjectionScreen />
                                                </div>
                                                <select
                                                    id="project_pages"
                                                    name="project_pages"
                                                    onChange={handleChange}
                                                    className="form-control"
                                                >
                                                    <option value="" disabled selected>Select number of pages</option>
                                                    <option value='5'>5</option>
                                                    <option value="10">10</option>
                                                    <option value="15">15</option>
                                                    <option value="more">More</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="hosting" className="form-label">
                                                Domain & Hosting Purchased :
                                            </label>
                                            <div className="input-group input-group-merge">
                                                <div className="input-icon landingpage">
                                                    <AiOutlineFundProjectionScreen />
                                                </div>
                                                <select
                                                    id="hosting"
                                                    name="hosting"
                                                    onChange={handleChange}
                                                    className="form-control"
                                                >
                                                    <option value="" disabled selected>Domain Hosting</option>
                                                    <option value="yes">yes</option>
                                                    <option value="no">No</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="suggested_budget" className="form-label">
                                                Your Budget :
                                            </label>
                                            <div className="input-group input-group-merge">
                                                <div className="input-icon landingpage">
                                                    <MdAttachMoney />
                                                </div>
                                                <input
                                                    type="text"
                                                    onChange={handleChange}
                                                    id="project"
                                                    name="suggested_budget"
                                                    placeholder="Suggested Budget"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    </>)}

                                    <div className="form-group col-md-6 ">
                                        <label htmlFor="file_upload" className="form-label">
                                            Upload File:
                                        </label>
                                        <input
                                            type="file"
                                            id="file_upload"
                                            onChange={handleImagefile}
                                            name="file_upload"
                                            accept="image/*"
                                            className="form-control-file"
                                        />
                                        <p className="text-muted"><small>Any Reference (optional) : </small> </p>
                                    </div>
                                    <div className="form-group col-12">
                                        <label htmlFor="description" className="form-label">
                                            Project Description :
                                        </label>
                                        <textarea
                                            rows={3}
                                            type="text"
                                            id="description"
                                            onChange={handleChange}
                                            className="form-control"
                                            name="project_description"
                                            placeholder="Please provide a brief description of your project, including the main purpose and goals."
                                            defaultValue={""}
                                        />
                                    </div>
                                </div>

                                <button
                                    className="btn btn-outline-success float-right"
                                    type="submit">
                                    Submit
                                </button>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Servicequotation;
