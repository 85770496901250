import React from 'react'
import { Link } from 'react-router-dom'

const Condition = () => {
  return (
 <div>


  <div className="container-fluid">
    <div className="row">
      <div className="col about-hero-section-dark">
        <h2 className="d-flex text-center justify-content-center align-items-center about-us-hadding" data-aos="zoom-in">Term &amp; Condition</h2>
        <ul className="d-flex text-center justify-content-center align-items-center">
          <li><Link to="/home" className="about-us-links">Home </Link></li>
          <li><Link to="/" className="about-us-links"> / </Link></li>
          <li><Link to="/terms-and-conditions" className="about-us-links">Term &amp; Condition</Link></li>
        </ul>
      </div>
    </div>
  </div>

  <section className="t-con-sec py-4">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <ol>
            <li><span className="font-weight-bold">Acceptance of Terms</span>
              <p>By using the services provided by Sion Datamatics (India) Private Limited, you agree to be bound
                by these terms and conditions. If you do not agree with any part of these terms, please do not
                use our services.</p>
            </li>
            <li><span className="font-weight-bold">Definitions</span>
              <p>"We," "us," or "our" refers to Sion Datamatics (India) Private Limited.
                "Client" refers to the individual or entity using our services.
                "Services" refers to the products, solutions, or any other services provided by us.
                3. Service Description.</p>
            </li>
            <li><span className="font-weight-bold">Pricing and Payments</span>
              <p>Payment terms and pricing details will be outlined in specific service agreements or invoices.
                The client is responsible for payment as agreed upon in these documents. Failure to make
                payments may result in the suspension or termination of services.</p>
            </li>
            <li><span className="font-weight-bold">Confidentiality</span>
              <p>Both parties agree to keep confidential any proprietary, sensitive, or non-public information
                shared during the course of our engagement.</p>
            </li>
            <li><span className="font-weight-bold">Intellectual Property</span>
              <p>Unless otherwise stated in a separate agreement, any intellectual property created as a result
                of our services remains the property of Sion Datamatics (India) Private Limited. The client is
                granted a non-exclusive, non-transferable license to use this intellectual property for their
                business purposes.</p>
            </li>
            <li><span className="font-weight-bold">Termination</span>
              <p>We reserve the right to terminate our services at any time for any reason, including but not
                limited to non-payment or a breach of these terms and conditions.</p>
            </li>
            <li> <span className="font-weight-bold">Warranty and Liability</span>
              <p>We strive to provide our services with the utmost care and professionalism. However, we make no
                warranties, express or implied, regarding the accuracy, reliability, or suitability of our
                services for any specific purpose.</p>
            </li>
            <li><span className="font-weight-bold">Indemnification</span>
              <p>The client agrees to indemnify and hold us harmless from any claims, losses, or damages arising
                from the client's use of our services.</p>
            </li>
            <li><span className="font-weight-bold"> Limitation of Liability</span>
              <p>In no event shall Sion Datamatics (India) Private Limited be liable for any direct, indirect,
                special, incidental, or consequential damages.</p>
            </li>
            <li><span className="font-weight-bold">Governing Law</span>
              <p>These terms and conditions are governed by and construed in accordance with the laws of India.
                Any disputes will be subject to the exclusive jurisdiction of the courts in India.</p>
            </li>
            <li><span className="font-weight-bold">Changes to Terms</span>
              <p>We reserve the right to update these terms and conditions at any time. Clients will be notified
                of any changes, and continued use of our services implies acceptance of the updated terms.</p>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </section>
</div>


  )
}

export default Condition