import { NavLink, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Modalform from "./ModelForm";
import axios from 'axios';

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    zIndex: 1000,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1100,
  },
};
const Header = () => {
  const [menuData, setMenuData] = useState([]);
  const [services, setService] = useState([]);
  const [isSticky, setIsSticky] = useState(false);
  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = "black";
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 80) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const response = await fetch(
          "https://www.sdmipl.com/portal/api/menu-list"
        );
        const data = await response.json();
        // console.log(data)
        setMenuData(data.menu);
        if (data.menu) {
          setMenuData(data.menu);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchMenuData();
  }, []);
  useEffect(() => {
    const service = async () => {
      try {
        const res = await axios.get(`https://sdmipl.com/portal/api/view-service-card`);
        setService(res.data.result);
      } catch (error) {
        console.error('error fetching', error);
      }
    }
    service();
  }, []);
  const renderSubMenu = (subMenuItems) => {
    if (!subMenuItems || subMenuItems.length === 0) {
      return null;
    }
    return (
      <>
        {subMenuItems.map((subMenuItem, index) => (
          <li key={subMenuItem.id || index} className="nav-item dropbtn">
            <NavLink
              className="nav-link sion-navigation-sec-list-tems"
              to={subMenuItem.slug}>
              {subMenuItem.title}
            </NavLink>
            {renderSubMenu(subMenuItem.submenu)}
          </li>
        ))}
      </>
    );
  };
  return (
    <div className="relative">
      <div className="main-top-nav">
        <div className="left-top-nav">
          <div className="left-left-nav-items">
            <div className="left-nav-call-icon">
              <Link
                to="/"
                className="left-nav-social-icon"
                aria-label="social media links">
                <img
                  src="https://www.sdmipl.com/assets/images/phone_call.png"
                  alt="contact-us"
                  title="Contact-us"
                />
              </Link>
            </div>
            <div className="left-nav-call-number">
              <Link
                to="tel:+917880136882"
                aria-label="contact-us"
                className="left-nav-Number">
                +91 78801 36882
              </Link>
            </div>
          </div>
          <div className="left-left-nav-items">
            <div className="rigth-nav-emai-icon">
              <Link to="/" className="left-nav-social-icon">
                <img
                  src="https://www.sdmipl.com/assets/images/email.png"
                  alt="Email sdmipl"
                  title="namaste@sdmipl.com"
                />
              </Link>
            </div>
            <div className="right-nav-Mail">
              <Link
                to="https://mail.google.com/mail/?view=cm&fs=1&to=namaste@sdmipl.com"
                className="left-nav-Number">
                namaste@sdmipl.com
              </Link>
            </div>
          </div>
        </div>
        <div className="right-top-nav">
          <div className="right-Right-nav-section">
            <p className="Right-nav-follow">Follow us</p>
          </div>
          <div className="right-Left-nav-section">
            <Link
              to="https://www.facebook.com/siondatamaticsindia"
              className="left-nav-social-icon"
              aria-label="social media-page"
              target="_blank">
              <img
                src="https://www.sdmipl.com/assets/images/facebook.png"
                alt="Sion-Cover"
                title="Facebook"
                className="socials-icons"
              />
            </Link>
            <Link
              to="https://www.instagram.com/siondatamaticsindia/"
              className="left-nav-social-icon"
              aria-label="social media-page"
              target="_blank">
              <img
                src="https://www.sdmipl.com/assets/images/instagram.png"
                alt="Sion-Cover"
                title="instagram"
                className="socials-icons"
              />
            </Link>
            <Link
              to="https://twitter.com/siondatamatics"
              className="left-nav-social-icon"
              aria-label="social media-page"
              target="_blank">
              <img
                src="https://www.sdmipl.com/assets/images/twitter.png"
                alt="Sion-Cover"
                title="twitter"
                className="socials-icons"
              />
            </Link>
            <Link
              to="https://www.linkedin.com/in/sion-datamatics-india-private-limited/"
              aria-label="social media-page"
              className="left-nav-social-icon"
              target="_blank">
              <img
                src="https://www.sdmipl.com/assets/images/linkdin.png"
                title="linkdin"
                alt="Sion-Cover"
                className="socials-icons"
              />
            </Link>
            <Link
              to="https://www.youtube.com/@SionDatamaticsindia"
              className="left-nav-social-icon"
              aria-label="social media-page"
              target="_blank">
              <img
                src="https://www.sdmipl.com/assets/images/youtube.png"
                alt="Sion-Cover"
                title="youtube"
                className="socials-icons"
              />
            </Link>
          </div>
        </div>
      </div>

      <section className={`nav-sec  ${isSticky ? "sticky active" : "nav-sec"}`}>
        <nav className="navbar navbar-expand-lg main_menu navbar-light">
          <div className="container">
            <Link
              className="navbar-brand navbar-logo"
              to="/"
              aria-label="IT Company in Indore Madhya Pradesh India">
              <img
                src="https://www.sdmipl.com/assets/images/sionlogo.png"
                alt="Sion-DataMatics"
                title="Best IT Company in Indore Madhya Pradesh India"
                className="img-fluid"
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav m-auto navigation-sdmi nav-link ">
                <li className={"nav-item  nav-text "}>
                  <NavLink
                    aria-label="BestIT Company"
                    className="nav-link nav-text sion-navigation-sec-list-tems"
                    to={"/"}>
                    Home
                  </NavLink>
                </li>
                {/* new change */}
                <li className="nav-item nav-text dropdown">
                  <NavLink
                    className="nav-link nav-text sion-navigation-sec-list-tems"
                    aria-label="blog page details"
                    to="/Services">
                    Services
                  </NavLink>
                  {services && services.length > 0 && (
                    <div className="dropdown-content">
                      <ul>
                      {services.map((item, index) => (
                        <li key={item.id || index}>
                          <Link aria-label='nav-item text-dark ' to={`/service/${item.slug}`} title={item.service_card_title}>
                            {item.service_card_title}
                          </Link>
                        </li>
                      ))}
                    </ul>

                    </div>
                  )}
                </li>

                {menuData && menuData.map((menuItem, index) => (
                   <li
                   key={menuItem.id || index}
                   className={`nav-item nav-text ${menuItem.submenu ? "dropdown" : ""}`}>
                   <NavLink
                     className="nav-link nav-text sion-navigation-sec-list-tems"
                     to={menuItem.slug ? `/${menuItem.slug}` : "#"}>
                     {menuItem.title}
                   </NavLink>
                   {menuItem.submenu && (
                     <div className="dropdown-content">
                       <ul>
                         {renderSubMenu(menuItem.submenu)}
                       </ul>
                     </div>
                   )}
                 </li>
                ))}
                <li className={"nav-item nav-text"}>
                  <NavLink
                    className="nav-link  nav-text sion-navigation-sec-list-tems"
                    aria-label="blog page details"
                    title="Our Team"
                    to={"/our-team"}>
                    Team
                  </NavLink>
                </li>
                <li className={"nav-item nav-text"}>
                  <NavLink
                    className="nav-link  nav-text sion-navigation-sec-list-tems"
                    aria-label="blog page details"
                    to={"/blog"}
                    title="Blogs">
                    Blog
                  </NavLink>
                </li>
                <li className={"nav-item"}>
                  <NavLink
                    className="nav-link  nav-text sion-navigation-sec-list-tems"
                    to={"/contact-us"}>
                    Contact Us
                  </NavLink>
                </li>
              </ul>
              <ul className="menu_icon d-flex flex-wrap mb-0">
                <li>
                  <button className="btn book-t">
                    <div className="sion-navigation-sec3">
                      <Link
                        to=""
                        onClick={openModal}
                        className="btn sion-navigation-sec3-btn">
                        Get a Free Quote
                        <img
                          src="https://www.sdmipl.com/assets/images/navarrow.png"
                          alt="Sion-Cover"
                          title="Get a Free Quote"
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                  </button>
                  <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Modal">
                    <h2 ref={(_subtitle) => (subtitle = _subtitle)}> </h2>
                    <Modalform closeModal={closeModal} />
                  </Modal>
                  <div></div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </section>
    </div>
  );
};

export default Header;
