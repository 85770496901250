import { BrowserRouter, Routes, Route } from "react-router-dom";
import "../src/assets/home.css";
import Layout from "./pages/common/Layout";
import Privacy from "./pages/bottom/Privacy&Policy";
import Condition from "./pages/bottom/Term&Condition";
import HandleError from "./pages/common/HandleError";
import Singlepage from "./pages/common/Singlepage";
import Home from "./pages/component/Home";
import Login from "./Login/Login";
import Contact from "./pages/component/Contact";
import Blog from "./pages/component/Blog";
import BLogpage from "./pages/component/Blogpage";
import Quotation from "./pages/common/Quotation";
import ScrollToTop from "./Scroll";
import Codegenrate from "./pages/Chatboat/QRgenrate";
import OurTeam from "./pages/component/OurTeam";
import Seopge from "./pages/component/seopge";
import Services from "./pages/component/Services";
import Landingpage from "./pages/component/Landingpage";
import Servicequotation from "./pages/component/servicequotation";
import Singleserviceprice from "./pages/common/singleserviceprice";
import Service from "./pages/component/Services/Service";
import PlanQuotaion from "./pages/component/PlanQuotaion";
import Career from "./pages/component/Career";

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/:slug" element={<Singlepage />} />
            <Route path="/service/:slug" element={<Service />} />
            <Route path="/terms-and-conditions" element={<Condition />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/login" element={<Login />} />
            <Route path="/our-team" element={<OurTeam />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/quotation" element={<Quotation />} />
            <Route path={`blog/:slug`} element={<BLogpage />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/qrgenrate" element={<Codegenrate />} />
            <Route path="/seopge" element={<Seopge />} />
            <Route path='/services' element={<Services />} />
            <Route path='/servicequotation' element={<Servicequotation />} />
            <Route path="/singleserviceprice" element={<Singleserviceprice />} />
            <Route path="/planquotaion" element={<PlanQuotaion />} /> 
            <Route path="/career" element={<Career />} />
            <Route path="404" element={<HandleError />} />
            <Route path="*" element={<HandleError />} />
        </Route>
            <Route path='/internship/registration' element ={<Landingpage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
