import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { Helmet } from "react-helmet";
const Contact = () => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
    description: "",
    website: "",
  });
  const navigate = useNavigate();
  const linkurl = useLocation();
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const name = formData.name;
      await axios.post(
        "https://www.sdmipl.com/portal/api/contact_us",
        formData
      );
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        text: name,
        title: "Submited successfully Thank you",
      });
      navigate("/");
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  return (
    <>
      <Helmet>
        <title>
          Sion Datamatics | Contact us | Connect with Sion Datamatics | Call Now
        </title>
        <meta
          name="description"
          content={
            "Connect with Sion Datamatics for Business Investors, Media, Analysts. Post queries, jobs, alliances, partnerships, and more.Get prompt support: +91-7880109056 "
          }
        />
        <meta
          name="author"
          content={
            "Sion Datamatics"
          }
        />
        <meta
          name="keywords"
          content={
            "Contact us, Visit us, Connecting us, Call now, Get prompt support: +91-7880109056, contact with us"
          }
        />
        <meta property="og:title" content="Sion Datamatics | support  contact Company in Indore India" />
        <meta property="og:description"
          content="Sion Datamatics: contact pages, crafting innovative solutions for businesses. Collaborate with us to shape new strategies for growth." />
        <meta name="twitter:title" content="Sion Datamatics India Pvt. Ltd." />
        <meta name="twitter:description"
          content="Sion Datamatics: Indore's premier software company, crafting innovative solutions for businesses. Collaborate with us to shape new strategies for growth." />
        <Link rel="canonical" to={linkurl.pathname || ""} />
      </Helmet>
      <div className="container-fluid">
        <div className="row">
          <div className="col about-hero-section-dark">
            <h1
              className="d-flex text-center justify-content-center align-items-center about-us-hadding"
              data-aos="zoom-in">
              CONTACT US
            </h1>
            <ul className="d-flex text-center justify-content-center align-items-center">
              <li>
                <Link to={"/"} className="about-us-links " aria-label="home-page">
                  Home
                </Link>
              </li>
              <li>
                <Link to="#" className="about-us-links">
                  /
                </Link>
              </li>
              <li>
                <Link to="#" className="about-us-links">
                  Contact us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container">
        <h2 className="text-center mt-3 font-weight-bold">Get In Touch</h2>
        <div className="office-information">
          <div className="row">
            <div
              className="col-lg-4 col-sm-12 office-info-sec"
              data-aos="flip-up">
              <div className="office-info-sec-top">
                <Link to="#" className="office-info-sec-top-image">
                  <img
                    src="assets\images\officephone.png"
                    title="Contact"
                    alt="contact-us"
                  />
                </Link>
              </div>
              <div className="office-info-sec-bottom">
                <div className="office-info-sec-bottom1">
                  <p className="office-info-sec-hadding">Office Phone</p>
                </div>
                <div className="office-info-sec-bottom2">
                  <p className="office-info-sec-data">
                    <Link
                      aria-label="details sion links"
                      to="tel:+917880136882"
                      target="_blank"
                      className="office-info-sec-datas">
                      +91 78801 36882
                    </Link>
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-12 office-info-sec"
              data-aos="flip-down">
              <div className="office-info-sec-top">
                <Link
                  aria-label="details sion links"
                  to="https://mail.google.com/mail/?view=cm&fs=1&to=namaste@sdmipl.com"
                  target="_blank"
                  data-toggle="tooltip"
                  data-placement="left"
                  title="namaste@sdmipl.com"
                  className="office-info-sec-top-image">
                  <img
                    src="https://www.sdmipl.com/assets\images\contactemail.png"
                    title="email"
                    alt="contact-us"
                  />
                </Link>
              </div>
              <div className="office-info-sec-bottom">
                <div className="office-info-sec-bottom1">
                  <p className="office-info-sec-hadding">Email</p>
                </div>
                <div className="office-info-sec-bottom2">
                  <p className="office-info-sec-data">
                    <Link
                      aria-label="details sion links"
                      to="https://mail.google.com/mail/?view=cm&fs=1&to=namaste@sdmipl.com"
                      target="_blank"
                      data-toggle="tooltip"
                      data-placement="left"
                      title="namaste@sdmipl.com"
                      className="office-info-sec-datas">
                      namaste@sdmipl.com
                    </Link>
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-12 office-info-sec"
              data-aos="flip-up">
              <div className="office-info-sec-top">
                <Link to="#" className="office-info-sec-top-image">
                  <img
                    src="https://www.sdmipl.com/assets\images\contactlocation.png"
                    title="Contact-location"
                    alt="contact-us"
                  />
                </Link>
              </div>
              <div className="office-info-sec-bottom">
                <div className="office-info-sec-bottom1">
                  <p className="office-info-sec-hadding">Office Address</p>
                </div>
                <div className="office-info-sec-bottom2">
                  <p className="office-info-sec-data">
                    <Link
                      aria-label="details sion links"
                      to="https://mail.google.com/mail/?view=cm&fs=1&to=namaste@sdmipl.com"
                      target="_blank"
                      data-toggle="tooltip"
                      data-placement="left"
                      title="namaste@sdmipl.com"
                      className="office-info-sec-datas">
                      11 5, Nath Mandir Rd, in front of Arora Bhawan, South
                      Tukoganj, Indore
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*Contact Hero*/}
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 contact-hero-section col-sm-12" data-aos="flip-left">
            <img
              src="assets\images\contacthero.png"
              className="img-fluid"
              title="contact-us"
              alt="contact-us"
            />
          </div>
          <div
            className="contact-out-section col-lg-6 col-sm-12"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration={1000}>
            <h3 className="contact-form-get d-flex">Get a Quote,</h3>
            <h4 className="text-muted"><small>Send a Message</small></h4>
            <form
              id="myForm"
              onSubmit={handleSubmit}
              className="needs-validation"
              noValidate="">
              <div className="row">
                <div className="col-lg-6 col-sm-12 form-group form-group-space">
                  <div className="form-icon-control">
                    <input
                      type="text"
                      className="form-control form-input-control"
                      name="firstname"
                      onChange={handleChange}
                      placeholder="First Name"
                      required
                    />
                    <div className="fotm-icon">
                      <i className="fa fa-user" />
                    </div>
                  </div>
                  <div className="invalid-feedback">
                    Please enter your First Name.
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12 form-group form-group-space">
                  <div className="form-icon-control">
                    <input
                      type="text"
                      className="form-control form-input-control"
                      onChange={handleChange}
                      name="lastname"
                      placeholder="Last Name"
                    />
                    <div className="fotm-icon">
                      <i className="fa fa-user" />
                    </div>
                  </div>
                  <div className="invalid-feedback">
                    Please enter your Last Name.
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-sm-12 form-group form-group-space">
                  <div className="form-icon-control">
                    <input
                      type="text"
                      className="form-control form-input-control"
                      name="mobile"
                      onChange={handleChange}
                      placeholder="Mobile Number"
                      maxLength="10"
                      pattern="\d{10}"
                      onInput={(e) => e.target.value = e.target.value.replace(/\D/g, '')}
                      required
                    />
                    <div className="invalid-feedback">happy bharthday arpit bhai
                      Please enter your Contact Number.
                    </div>
                    <div className="fotm-icon">
                      <i className="fa fa-phone" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12 form-group form-group-space">
                  <div className="form-icon-control">
                    <input
                      type="email"
                      className="form-control form-input-control"
                      name="email"
                      onChange={handleChange}
                      placeholder="Email ID"
                      required
                    />
                    <div className="fotm-icon">
                      <i className="fa fa-envelope" />
                    </div>
                  </div>
                  <div className="invalid-feedback">
                    Please enter a valid email address.
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col form-group form-group-space">
                  <div className="form-icon-control">
                    <input
                      type="link"
                      className="form-control form-input-control"
                      name="website"
                      onChange={handleChange}
                      placeholder="subject"
                      required
                    />
                    <div className="invalid-feedback">
                      Please enter your website.
                    </div>
                    <div className="fotm-icon">
                      <i className="fa fa-globe" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col form-group form-group-space">
                  <div className="form-icon-control">
                    <textarea
                      className="form-control form-input-control"
                      id="exampleFormControlTextarea1"
                      rows={5}
                      name="description"
                      onChange={handleChange}
                      placeholder="message"
                    />
                    <div className="invalid-feedback">
                      Please enter your messege.
                    </div>
                    <div className="fotm-icon">
                      <i className="fa fa-comment" />
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="sion-navigation-sec3 px-2 w-auto text-light">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
