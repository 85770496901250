import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
const OurTeam = () => {
    const [Team, setTeams] = useState('');
    const linkurl = useLocation();
    console.log("link url",linkurl)
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://sdmipl.com/portal/api/team-member-view`);
                // console.log("Our Team", response)
                setTeams(response.data);
            } catch (error) {

                console.error("data", error);
            }
        };
        fetchData();
    }, []);
    return (
        <div className='w-full md:w-[85%]  mx-auto'>
            <Helmet>
        <title>
          Sion Datamatics | Our Team | Connect with Sion Datamatics | Call Now
        </title>
        <meta
          name="description"
          content={
            "Connect with Sion Datamatics for Business Investors, Media, Analysts. Post queries, jobs, alliances, partnerships, and more.Get prompt support: +91-7880109056 "
          }
        />
        <meta
          name="author"
          content={
            "Sion Datamatics"
          }
        />
        <meta
          name="keywords"
          content={
            "Contact us, Visit us, Connecting us, Call now, Get prompt support: +91-7880109056, contact with us"
          }
        />
        <meta property="og:title" content="Sion Datamatics | Our Team  Company in Indore India" />
        <meta property="og:description"
          content="Sion Datamatics: Our Team, crafting innovative solutions for businesses. Collaborate with us to shape new strategies for growth." />
        <meta name="twitter:title" content="Sion Datamatics India Pvt. Ltd." />
        <meta name="twitter:description"
          content="Sion Datamatics: Indore's premier software company, crafting innovative solutions for businesses. Collaborate with us to shape new strategies for growth." />
           <Link rel="canonical" to={linkurl.pathname || ""} />
      </Helmet>
            <div className="container-fluid">
                <div className="row">
                    <div className="col about-hero-section-dark">
                        <h1 className="d-flex text-center justify-content-center align-items-center about-us-hadding"
                            data-aos="zoom-in">Team</h1>
                        <ul className="d-flex text-center justify-content-center align-items-center">
                            <li><Link to="/" aria-label='home navigate' className="about-us-links">Home </Link></li>
                            <li><Link to="#" className="about-us-links"> / </Link></li>
                            <li><Link to="#" className="about-us-links">Team</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="sion-team-back mb-3">
                <div className="container">
                    <div className="row">
                        <div className="col d-flex justify-content-center align-items-center py-5 im">
                            <h2 className="sion-team text-dark">Meet Our Brilliant Minds</h2>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div>
                        <div className="row">
                            {Team && Team.map((Team) => (
                                <div className="item col-md-4" key={Team.id}>
                                    <div className="staff">
                                              <div className="img-wrap d-flex align-items-stretch">
                                             <div className="img align-self-stretch">
                                                <img className='teamimg' src={Team.profile_image} alt={Team.name} />
                                            </div>
                                        </div>
                                        <div className="text text-center">
                                            <h3 className="mb-2">{Team.name}</h3>
                                            <span className="position mb-2">{Team.designation}</span>
                                            {/* <div className="faded">
                                                <ul className="ftco-social text-center">
                                                    <li className="ftco-animate"><Link to="#"
                                                        className="d-flex align-items-center justify-content-center"><span
                                                            className="fa fa-twitter"></span></Link></li>
                                                    <li className="ftco-animate"><Link to="#"
                                                        className="d-flex align-items-center justify-content-center"><span
                                                            className="fa fa-facebook"></span></Link></li>
                                                    <li className="ftco-animate"><Link to="#"
                                                        className="d-flex align-items-center justify-content-center"><span
                                                            className="fa fa-google"></span></Link></li>
                                                    <li className="ftco-animate"><Link to="#"
                                                        className="d-flex align-items-center justify-content-center"><span
                                                            className="fa fa-instagram"></span></Link></li>
                                                </ul>
                                                <p>I am an ambitious workaholic, but apart from that, pretty simple person.</p>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <h4 className='text-center text-muted d-none'><small>Contact Our Team</small></h4>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default OurTeam