import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Link } from 'react-router-dom';
import 'swiper/css/effect-cube';
import { Autoplay, EffectCube, Navigation } from 'swiper/modules';

const Blogs_slider = () => {
    const [Blog, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const blogResponse = await fetch(
            "https://www.sdmipl.com/portal/api/blogs"
          );
        const blogData = await blogResponse.json();
        setBlogs(blogData.blogs);
        setLoading(false);
      }
      catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  if (loading && !Blog) {
    return (
      <div className="lodindingcontner">
        <div id="progressbar">
          <span id="loading"></span>
          <div id="load">Loading...</div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="sion-blog-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
            <div className="sion-blog-sec-top" data-aos="zoom-in">
                <h5 className="text-center sion-blog-sec-hadding h3 l">
                  Our Blogs
                </h5>
                <p className="sion-blog-sec-pg">
                  "Breaking Down Complex Tech Topics for Your Understanding."
                </p>
              </div>
            </div>
            <div className='col-lg-12'>
              <div className='web-customer-sec-bottom'>
                <Swiper
                  autoplay={{
                    delay: 2000,  
                    grabCursor:false,
                    speed:200,
                    spaceBetween:18,
                    disableOnInteraction: true,
                    pauseOnMouseEnter: true,
                    navigation:false,
                    }}
                    rewind={true}
                    effect={'EffectCube'}
                    cubeEffect={{
                        shadow: true,
                        slideShadows: true,
                        shadowOffset: 20,
                        shadowScale: 0.94,
                      }}
                  
                  slidesPerView={1}
                  spaceBetween={1}
                  data-swiper-parallax="1000"
                  data-swiper-parallax-opacity="0.5"
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  breakpoints={{
                    425: {
                      slidesPerView: 1,
                      spaceBetween: 15,
                    },
                    640: {
                      slidesPerView: 3,
                      spaceBetween: 15,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 15,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 15,
                    },
                  }}
                  modules={[ Autoplay,Navigation,EffectCube]}
                  className="mySwiper"
                >
                  
                  {
                    Blog && Blog.length > 0 &&
                    Blog.map((item, i) => (
                     <SwiperSlide key={item.id || i}>
                     <div className="gap-4 mx-2 " >
                      <div className="event-item">
                        <div className="thumb">
                          <img
                              
                            src={`${item
                                ? item.image
                                : "https://edumen.codelayers.net/images/blog/02.png"
                              }`}
                             title={item.title}
                              alt={item.title}
                          />
                        </div>

                        <Link
                          className="linktagcolor"
                          to={`blog/${item.slug}`}
                          aria-label={item.title}>
                          <div className="event-content">
                            <div className="date">
                              {item.updated_at.slice(0, 10)}
                            </div>
                            <h5>{item.title} </h5>
                            <p>{item.meta_description}</p>
                            <div className="time">
                              {new Date(item.created_at).toLocaleDateString(
                                "en-GB"
                              )}
                              <i className="fa-solid fa-arrow-right-long" />{" "}
                              <strong> India</strong>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                     </SwiperSlide>
                    ))
                  }
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Blogs_slider;
