import { Helmet } from "react-helmet";
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import HandleError from "../../common/HandleError";
import Singleserviceprice from "../../common/singleserviceprice";
const Service = () => {
    const { slug } = useParams();
    const [postData, setPostData] = useState(null);
    const linkurl = useLocation();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://www.sdmipl.com/portal/api/single-post/${slug}`);
                const data = await response.json();
                setPostData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [slug]);

    if (!postData) {
        return (
            <>
                <div className="col-lg-12 col-md-12 col-sm-12 py-4">
                  <div className="loader_detail d-flex justify-content-center">
                    <span className="loader"></span>
                  </div>
                </div>
            </>
        )
    } 

    if (postData.post == null) {

        return (
            <div>
                <HandleError />
            </div>
        );

    }
    else {
        const { meta_title, meta_description, meta_keyword, content } = postData.post;

        return (
            <div>
                <Helmet>

                    <title name="title">
                        {meta_title || "Best IT Company in Indore Madhya Pradesh India"}
                    </title>
                    <meta
                        name="description"
                        content={
                            meta_description || "Sion Datamatics leading software development company in Indore, delivering innovative solutions for businesses"
                        }
                    />

                    <meta
                        name="keywords"
                        content={
                            meta_keyword || "UI/ UX Designing, Logo Designing , Content Writing ,Search Engine Optimization,Video Editing , 2D/3D Animation ,  Graphic Designing , Digital Marketing , Mobile App Development "
                        }
                    />
                    <Link rel="canonical" to={linkurl.pathname || ""} />
                </Helmet>
                <div dangerouslySetInnerHTML={{ __html: content }} />
                <Singleserviceprice />
            </div>
        );
    }

};

export default Service