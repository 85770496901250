import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { BsPersonCircle } from 'react-icons/bs';
import { IoIosMail } from 'react-icons/io';
import { LuPhoneCall } from 'react-icons/lu';
import { FaLaptopCode } from "react-icons/fa";
import { Helmet } from 'react-helmet';
import oppoutunity from '../../assets/images/oppoutunity.jpg';
const Career = () => {
    const [data, setdata] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        course_category: '',
        resume: '',
    });
    console.log(formData, 'jobrequest')
    const linkurl = useLocation();
    useEffect(() => {
        const data = async () => {
            try {
                const res = await axios.get('https://www.sdmipl.com/portal/api/view-approved-jobpost');
                setdata(res.data.result);
                console.log('carrer', res.data.result)
            } catch (error) {
                console.log('error', error)
            }
        }
        data();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const [expandedCardId, setExpandedCardId] = useState(false);

    const toggleDescription = (id) => {
        setExpandedCardId((prevId) => (prevId === id ? null : id));
    };
    const jobTitleset = (jobtitle) => {
        setFormData((prevData) => ({
            ...prevData,
            course_category: jobtitle,
        }))
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
           const res = await axios.post('https://www.sdmipl.com/portal/api/add-job-enquiry', formData);
           console.log(res,'add-job-enquiry');
            Swal.fire({
                icon: 'success',
                title:res.data.result,
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer);
                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                },
            });
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: 'Try Again....',
                text: 'Something Went Wrong',
            });
            console.error('Error submitting form:', err);
        }
    };
    return (
        <>
            <Helmet>
                <title>
                    Sion Datamatics | Carrer | Connect with Sion Datamatics | Call Now
                </title>
                <meta
                    name="description"
                    content={
                        "Connect with Sion Datamatics for Business Investors, Media, Analysts. Post queries, jobs, alliances, partnerships, and more.Get prompt support: +91-7880109056 "
                    }
                />
                <meta
                    name="author"
                    content={
                        "Sion Datamatics"
                    }
                />
                <meta
                    name="keywords"
                    content={
                        "Contact us, Visit us, Connecting us, Call now, Get prompt support: +91-7880109056, contact with us"
                    }
                />
                <meta property="og:title" content="Sion Datamatics | Our Team  Company in Indore India" />
                <meta property="og:description"
                    content="Sion Datamatics: Carrer at Sion Datamatics , crafting innovative solutions for businesses. Collaborate with us to shape new strategies for growth." />
                <meta name="twitter:title" content="Sion Datamatics India Pvt. Ltd." />
                <meta name="twitter:description"
                    content="Sion Datamatics: Indore's premier software company, crafting innovative solutions for businesses. Collaborate with us to shape new strategies for growth." />
                <Link rel="canonical" to={linkurl.pathname || ""} />
            </Helmet>
            <div className={`container-fluid ${expandedCardId === null ? "bg-light" : "bg-dark"}`}>
                <div className="row">
                    <div className="col about-hero-section-dark">
                        <h1 className="d-flex text-center justify-content-center align-items-center about-us-hadding"
                            data-aos="zoom-in">CAREER</h1>
                        <ul className="d-flex text-center justify-content-center align-items-center">
                            <li><Link to="/" className="about-us-links">Home </Link></li>
                            <li><Link to="#" className="about-us-links"> / </Link></li>
                            <li><Link to="/career" className="about-us-links">Career</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* career */}
            <section className="career-section">
                <div className='container'>
                    <h2 className='d-flex justify-content-center font-weight-bold p-4'>Our Hiring Process</h2>
                    <div className=" d-flex m-4 p-1">
                        <p className='text-center mx-4 gap-3'>At Sion Datamatics India Pvt Ltd, we are committed to finding and hiring the best talent to join our team. Our recruitment process is designed to be fair, efficient, and transparent. Here's an overview of what you can expect when you apply for a position with us.</p>
                    </div>
                    <div className='d-flex align-items-center p-5'>
                        <div className='carrer_page_image col-lg-6'>
                            <img src={oppoutunity}
                                className='img-fluid'
                                style={{ height: 380, width: 570 }}
                                alt='Carrere_Oppourtunity'
                                title='Carrere_Oppourtunity'
                            />
                        </div>
                        <div className='Oppourtunity_text col-lg-6'>
                            <p className='carrer_page_Oppourtunity'> Opportunities For You </p>
                            <p className='font-weight-bold'> Renew Your Career Narratives </p>
                            <p className='text'>Your career narrative is not a fixed script, but a story that you have the power to rewrite. Embrace the opportunity to renew your narrative, infuse it with passion and purpose and watch as your professional journey unfolds in ways you never imagined.</p>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        {data && data.map((item) => (
                            <div className="col-lg-4 col-md-6" key={item.id}>
                                <div className="job-card mb-4 mt-2">
                                    <div className="job-card-header">
                                        <i className="fa-solid fa-lock arrow mt-2 mb-2" />
                                    </div>

                                    <div className="job-card-title font-weight-bold">{item.job_title}</div>
                                    <div className="job-card-subtitle" title={item.job_description}>
                                        {expandedCardId  === item.id
                                            ? item.job_description
                                            : item.job_description.slice(0, 100) +
                                            (item.job_description.length > 100 ? '...' : '')}</div>

                                    <div className="job-detail-buttons">
                                        <button className="search-buttons detail-button">
                                            {item.job_mode}
                                        </button>
                                        <button className="search-buttons detail-button">
                                            Min. {item.experience_required} Year
                                        </button>
                                        <button className="search-buttons detail-button">
                                            Senior Level
                                        </button>
                                        <button className="search-buttons detail-button">
                                            {item.job_location}
                                        </button>
                                    </div>
                                    <div className="job-card-buttons">
                                        <button
                                            type="button"
                                            className="search-buttons card-buttons"
                                            data-toggle="modal"
                                            data-target="#appliedform"
                                            onClick={() => jobTitleset(item.job_title)}
                                        >
                                            Apply Now
                                        </button>
                                        <button
                                            type="button"
                                            className="search-buttons card-buttons-msg"
                                            data-toggle="modal"
                                            data-target="#appliedform1"
                                            onClick={() => toggleDescription(item.id)}
                                        >
                                            {expandedCardId === item.id ? 'Show Less' : 'Read More'}
                                        </button>
                                        </div>
                                    </div>
                                </div>
                        ))}
                    </div>
                </div>
                {/* Modal */}
                <div
                    className="modal fade"
                    id="appliedform"
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="appliedformTitle"
                    aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className='text-muted'>Apply Now</h3>
                                <button
                                    type="button"
                                    className="close p-0 text-danger fs-6"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>

                            </div>
                            <div className="modal-body">
                                <form className="landingPagefrm" onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label>Name</label>
                                        <div className="input-group input-group-merge">
                                            <div className="input-icon landingpage">
                                                <BsPersonCircle />
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Your Name"
                                                name="name"
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Email</label>
                                        <div className="input-group input-group-merge">
                                            <div className="input-icon landingpage">
                                                <IoIosMail />
                                            </div>
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="Enter Your Email"
                                                name="email"
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Phone Number</label>
                                        <div className="input-group input-group-merge">
                                            <div className="input-icon landingpage">
                                                <LuPhoneCall />
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Your Phone Number"
                                                name="phone"
                                                maxLength={10}
                                                pattern='\d{10}'
                                                onChange={handleChange}
                                                onInput={(e) => e.target.value = e.target.value.replace(/\D/g, '')}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label>Select Course Category</label>
                                        <div className="input-group input-group-merge">
                                            <div className="input-icon landingpage">
                                                <FaLaptopCode />
                                            </div>
                                            <input
                                                className="form-control"
                                                type='text'
                                                value={formData.course_category}
                                                name="coursecategory"
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label> Upload Your Cv / Resume </label>
                                        <div className="input-group">
                                            <input
                                                type='file'
                                                name="resume"
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className='d-flex py-2 w-auto'>
                                        <button className="sion-navigation-sec3 px-2 w-auto text-light" type="submit"> Submit </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Career