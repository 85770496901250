import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { BsPersonCircle } from 'react-icons/bs';
import { IoIosMail } from 'react-icons/io';
import { LuPhoneCall } from 'react-icons/lu';
import { FaLaptopCode } from "react-icons/fa";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";

const Modalform = ({ closeModal }) => {
  const [Submenu, setSubmenu] = useState([]);
  const [image, setImage] = useState(null);
  const [showCustomInput, setShowCustomInput] = useState('');
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    project_type: "",
    services: "",
    project_description: "",
    file_upload: null,
    image_upload: image,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "project_type" && value === "more") {
      setShowCustomInput(!showCustomInput);
  }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formDataForSubmit = new FormData();
      formDataForSubmit.append("name", formData.name);
      formDataForSubmit.append("email", formData.email);
      formDataForSubmit.append("mobile", formData.mobile);
      formDataForSubmit.append("project_type", formData.project_type);
      formDataForSubmit.append("services", formData.services);
      formDataForSubmit.append(
        "project_description",
        formData.project_description
      );
      formDataForSubmit.append("file_upload", formData.file_upload);
      formDataForSubmit.append("image_upload", image);

      const res = await axios.post(
        "https://www.sdmipl.com/portal/api/projectlead-create",
        formDataForSubmit
      );
      console.log("data", res);

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        text: formData.name,
        title: "Submitted successfully. Thank you!",
      });
      closeModal();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const response = await fetch(
          "https://www.sdmipl.com/portal/api/services-list"
        );
        const data = await response.json();
        const services = data.service;
        setSubmenu(services);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchMenuData();
  }, []);

  return (
    <>
      <div className="row enquiry_modal">
        <div className="col-lg-12 col-md-12 col-12">
          <div className="max-w-md mx-auto p-6 rounded-md shadow-md">
            <form
              onSubmit={handleSubmit}
              encType="multipart/form-data"
              method="post">
              <fieldset className="">
                <div className="text-muted fw-normal modalformheading">
                  <span className=""></span> Let’s Talk & Bring Your Ideas To Life!
                </div>
                <button
                  onClick={closeModal}
                  className="btn btn-outline-danger text-lg btn-lg close-button float-right">
                  &times;
                </button>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="name" className="form-label">
                      Name:
                    </label>
                    <div className="input-group input-group-merge">
                      <div className="input-icon landingpage">
                        <BsPersonCircle />
                      </div>
                      <input
                        type="text"
                        onChange={handleChange}
                        id="name"
                        name="name"
                        placeholder="Enter Your Name"
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="email" className="form-label">
                      Email:
                    </label>
                    <div className="input-group input-group-merge">
                      <div className="input-icon landingpage">
                        <IoIosMail />
                      </div>
                      <input
                        type="email"
                        id="email"
                        onChange={handleChange}
                        placeholder="mail@gmail.com"
                        name="email"
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="mobile" className="form-label">
                      Mobile:
                    </label>
                    <div className="input-group input-group-merge">
                      <div className="input-icon landingpage">
                        <LuPhoneCall />
                      </div>
                      <input
                        type="text"
                        onChange={handleChange}
                        id="mobile"
                        name="mobile"
                        className="form-control"
                        placeholder="+91 XXXXXXXXXX"
                        maxLength="10"
                        pattern="\d{10}"
                        onInput={(e) => e.target.value = e.target.value.replace(/\D/g, '')}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="project" className="form-label">
                      Project Type:
                    </label>
                      <div className="input-group input-group-merge">
                        <div className="input-icon landingpage">
                          <AiOutlineFundProjectionScreen />
                        </div>
                        <select
                          id="project_type"
                          name="project_type"
                          onChange={handleChange}
                          className="form-control"
                        >
                          <option value="" disabled selected>Project Type</option>
                          <option value='law'>Law Website</option>
                          <option value='health'>Health Website</option>
                          <option value="Educational">Educational  Website</option>
                          <option value="E-Commerce">E-Commerce Website</option>
                          <option value='Quick-Commerce'>Quick-Commerce Website</option>
                          <option value="more" >More</option>
                        </select>
                      </div>
                      {showCustomInput && (
                        <input
                          type="text"
                          name="project_type"
                          placeholder="Enter your project type"
                          className="form-control mt-2"
                          onChange={handleChange}
                        />
                      )}
                  </div>
                </div>
                <div className="row mt-3 mb-2 ">
                  <div className="input-group input-group-merge col-lg-12">
                    <div className="input-icon landingpage">
                      <FaLaptopCode />
                    </div>
                    <select
                      className="form-control custom-select "
                      onChange={handleChange}
                      id="services"
                      name="services"
                      required
                      aria-label="Default select example">

                      {Submenu ? (
                        <>
                          <option key="default" defaultValue>
                            Select Services
                          </option>
                          {Submenu.map((value) => (
                            <option key={value.id} value={value.title}>
                              {value.title}
                            </option>
                          ))}
                        </>
                      ) : null}
                    </select>
                  </div>
                </div>
                <div className="row my-1">
                  <div className="form-group col-12">
                    <label htmlFor="description" className="form-label">
                      Project Description:
                    </label>
                    <textarea
                      rows={3}
                      type="text"
                      id="description"
                      onChange={handleChange}
                      className="form-control"
                      name="project_description"
                      required
                      defaultValue={""}
                    />
                  </div>
                </div>
              </fieldset>
              <button
                className="btn btn-outline-success mt-4 float-right"
                type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modalform;
