import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaIndianRupeeSign } from "react-icons/fa6";
import { VscCheckAll } from "react-icons/vsc";

function Singleserviceprice() {
    const navigate = useNavigate();
    const url_location = useLocation();
    const [data, setData] = useState('')
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get('https://sdmipl.com/portal/api/view-service-plan');
                setData(res.data.result)
                // console.log('Service Plan', res.data.result)
            } catch (error) {
                console.log(error, 'Error Fetching Data')
            }
        };
        fetchData();
    }, []);

    const handleJoinNow = (plan) => {
        navigate("/PlanQuotaion", { state: { PlanDesc: plan } });
        // console.log(plan, 'Selected Plan');
    };

    // change
    const path = url_location.pathname.split('/');
    const lastSlug = path[path.length - 1];
    const formatSlug = (slug) => {
        if (!slug) return '';
        return slug 
            .split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };
    // Format the last slug
    const formattedLastSlug = formatSlug(lastSlug);
    const filterData = data && data.filter(item => item.page === formattedLastSlug);

    return (
        <>
            <section className="pricing-section">
                <div className="container">
                    {
                        filterData && filterData.length > 0 ?
                            <div className="sec-title text-center">
                                <h2 className='borderplan'>Choose a Plan</h2>
                            </div> :
                            " "
                    }
                    <div className="outer-box ">
                        <div className="row d-flex justify-content-center">
                            {/* Pricing Block  */}
                            {filterData && filterData.length > 0 ?
                                filterData && filterData.slice(0, 3).map((item, index) => (
                                    <div className="pricing-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp" key={index} >
                                        <div className="inner-box">
                                            <div className="icon-box">
                                                <div className="icon-outer"><i className="fas fa-paper-plane"></i></div>
                                            </div>
                                            <div className="price-box">

                                                <div className="title">{item.plan_type}</div>
                                                <div className='title_Sr'>{item.service_title
                                                }</div>
                                                <h4 className="price"><FaIndianRupeeSign />{item.service_charge}</h4>
                                            </div>
                                            {/* <ul className="features"> */}
                                                {/* <li>
                                                    {item.service_description.split(/<br\s*\/?>/i).map((line, index) => (
                                                        line.trim() && (
                                                            <div key={index} className='d-flex'>
                                                                <div className='mr-3 pln_icon'>
                                                                    <VscCheckAll color="blue" />
                                                                </div>
                                                                <div dangerouslySetInnerHTML={{ __html: line }} />
                                                            </div>
                                                        )
                                                    ))}
                                                </li> */}
                                                {/* <li dangerouslySetInnerHTML={{__html:item.service_description}}/>  
                                              
                                            </ul> */}

                                            <div className="features" dangerouslySetInnerHTML={{__html:item.service_description}}>

                                            </div>

                                            <div className="btn-box">
                                                <Link to='#' onClick={(e) => { e.preventDefault(); handleJoinNow(item); }} className="theme-btn"> BUY Plan </Link>
                                            </div>
                                        </div>
                                    </div>
                                )) : ''}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Singleserviceprice;