import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Navigation } from 'swiper/modules';

const Slider = () => {
  const [Testimonial, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const testimonialResponse = await fetch(
          "https://www.sdmipl.com/portal/api/testimonial-view"
        );
        const testimonialData = await testimonialResponse.json();
        setTestimonials(testimonialData.records);
      }
      catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  if (loading && !Testimonial) {
    return (
      <div className="lodindingcontner">
        <div id="progressbar">
          <span id="loading"></span>
          <div id="load">Loading...</div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="web-customer-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="web-customer-sec-top" data-aos="zoom-in">
                <h4 className=" text-center text-light web-customer-sec-hadding h3">
                  Hear From Our Customer
                </h4>
                <p className="web-customer-sec-para">
                  The Voice of the People
                </p>
              </div>
            </div>
            <div className='col-lg-12 mt-3'>
              <div className='web-customer-sec-bottom'>
                <Swiper
                  autoplay={{
                    delay: 2000,
                    disableOnInteraction: true,
                    speed: 300,
                    pauseOnMouseEnter: true,
                  }}
                  slidesPerView={1}
                  spaceBetween={1}
                  data-swiper-parallax="-300"
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 15,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 15,
                    },
                    1024: {
                      slidesPerView: 2,
                      spaceBetween: 15,
                    },
                  }}
                  modules={[Autoplay, Navigation]}
                  className="mySwiper"
                >

                  {
                    Testimonial &&
                    Testimonial.map((data, i) => (
                        <SwiperSlide key={data.id || i}>
                          <div className="item" >
                            <div className="web-sion-customer-sec gap-2">
                              <div className="web-sion-customer-sec1 justify-content-between">
                                <div className="web-sion-customer-left">
                                  <p className="web-sion-customer-name">
                                    {data.name}
                                  </p>
                                  <p className="web-sion-customer-real">
                                    {data.designation}
                                  </p>
                                  <p className="web-sion-customer-rating">
                                    <span className="fa fa-star checked" />
                                    <span className="fa fa-star checked" />
                                    <span className="fa fa-star checked" />
                                    <span className="fa fa-star" />
                                    <span className="fa fa-star" />
                                  </p>
                                </div>
                                <div className="web-sion-customer-right ">
                                  <img
                                    src={`${data.image
                                      ? data.image
                                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0m5Cy4lXCbuyG54L0vuo3i5-ALavHe9KmhWA_wDM&s"
                                      }`}
                                    alt={data.designation}
                                    title={data.designation}
                                    className="customer-real"
                                  />
                                </div>
                              </div>
                              <div className="web-sion-customer-sec2 bg-transparent">
                                <p className="web-sion-customer-comment text-justify mr-3">
                                  {data.testimony}
                                </p>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                    ))
                  }
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Slider;