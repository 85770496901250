import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { Link, useLocation } from 'react-router-dom';

function Services() {
    const [service, setService] = useState([]);
    const linkurl = useLocation();

    useEffect(() => {
        const service = async () => {
            try {
                const res = await axios.get(`https://sdmipl.com/portal/api/view-service-card`);
                setService(res.data.result);
                // console.warn('servicepgeNew', res.data.result);
            } catch (error) {
                console.error('error fetching', error);
            }
        }
        service();
    }, []);
    return (
        <>
            <Helmet>
                <title>
                    Sion Datamatics India | Services | Online Services
                </title>
                <meta
                    name="description"
                    content={
                        "Sion Datamatics India Pvt Ltd offers Digital Marketing, Content Writing, Video Editing, SEO, Logo/UI/UX Design, Animation, Graphic Design, App, and Software Dev."
                    }
                />
                <meta
                    name="keywords"
                    content={
                        "Sion Datamatics India , Services , Online Services , Offline Services"
                    }
                />
          
                <Link rel="canonical" to={linkurl.pathname || ""} />
            </Helmet>
            <div className="container-fluid">
                <div className="row">
                    <div className="col about-hero-section-dark">
                        <h1 className="d-flex text-center justify-content-center align-items-center about-us-hadding"
                            data-aos="zoom-in">SERVICES</h1>
                        <ul className="d-flex text-center justify-content-center align-items-center">
                            <li><Link to="https://www.sdmipl.com/" className="about-us-links">Home </Link></li>
                            <li><Link to="/" className="about-us-links"> / </Link></li>
                            <li><Link to="https://www.sdmipl.com/services" className="about-us-links">Services</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* <!-- Support for Your Ecommerce Software --> */}
            <div className="container">
                <div className="row support-ecommerce-sec" data-aos="zoom-in">
                    <div className="col-lg-12 support-ecommerce-hadding">
                        <h2 className=" text-center font-weight-bold support-ecommerce-hadding">Our Services</h2>
                    </div>

                </div>
            </div>
            {/* dynamic */}

            {/* <!-- Support Ecommerce Software BOTTOM --> */}
            <section className="s-section pb-5">
                <div className="container">
                    <div className="row main-support-container">
                        {service && service.map((item) => (
                            <div className="col-lg-4 main-support-sf-container" data-aos="fade-up" data-aos-duration="3000">
                                <div className="support-container1">
                                    <img className='service_crd_img' src={item.service_card_image} alt={item.service_card_title} title={item.service_card_title} />
                                </div>
                                <div className="support-container2">
                                    <h3 className="text-center support-hadding font-weight-bold h6  ">{item.service_card_title}</h3>
                                </div>
                                <div className="support-container3">
                                    <p className="support-para text-justify">{item.service_card_description}
                                    </p>
                                </div>
                                <div className="for-top-line"></div>
                                <div className="support-container4">
                                    <div className="support-container4-1">
                                        <Link to={`/service/${item.slug}`} title={item.service_card_title} className="support-get-quote">Read
                                            More</Link>
                                    </div>
                                    <div className="support-container4-2">
                                        <Link to={`/service/${item.slug}`} title={item.service_card_title}  className="support-get-arrow">
                                            <img src="assets\images\lrft-arrow.png" alt="arrow" title="Read More" /></Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <h4 className='d-none'>Our Services</h4>
            </section>
        </>

    )
}

export default Services;
