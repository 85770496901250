import axios from "axios";
import { useState } from "react";
import { BiCheck, BiRupee } from "react-icons/bi";
import { BsWatch } from "react-icons/bs";
import Swal from "sweetalert2";
import { BsPersonCircle } from 'react-icons/bs';
import { IoIosMail } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";

const Landingpage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    city: '',
    course: '',
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
 const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post('https://www.sdmipl.com/portal/api/add-internship-enquiry', formData);
      console.log(res, 'internship registration');
      
      Swal.fire({
        icon: 'success',
        title: res.data.result,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });
  
      window.location.href = "https://internship.sdmipl.com";
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Try Again....',
        text: 'Something Went Wrong',
      });
      console.error('Error submitting form:', err);
    }
  };
  
  const [selectCourse, setSelectCourse] = useState("");

  const Course = [
    { label: "HTML CSS Javascript" },
    { label: "React Js" },
    { label: "Next Js" },
    { label: "Node Js Mongodb or MySQL" },
    { label: "Mern Stack" },
    { label: "PHP & Laravel" },
    { label: "Digital Marketing" },
    { label: "Python & DJango" },
    { label: "Flutter" },
    { label: "UX & UI" },
  ];

  return (
    <div className="container bg-light">
      <div className="d-flex flex-column justify-content-center align-items-center min-vh-100">
        <div className="container bg-white rounded shadow p-4">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h1 className="h4 font-weight-bold">Start Your IT Career for Just ₹ 5,000/Month!</h1>
              <p className="text-muted">Master the skills you need to succeed in the tech industry with our expert-led courses.</p>
              <p className="mt-2 text-muted lapge_text">
                <span className="font-weight-bold text-muted">Empower Your Web Journey: </span>
                Learn to code, design, and market with cutting-edge technologies. Take the first step toward a successful career in IT with us
              </p>
              <h2 className="display-5 mt-4">Start Your Career <span className="Init">In </span><span className="Itin"> IT</span></h2>
              <ul className="landing_page list-unstyled d-flex mt-4 py-4 gap-4">
                <li className="landing_page_card d-flex align-items-center mb-3  col-lg-6">
                  <div className="bg-warning iconlpge p-2 mx-3">
                    <BiCheck size={30} color="white" />
                  </div>
                  <div>
                    <p className="font-weight-bold">Eligibility: </p>
                    <p className="">10+2</p>
                  </div>
                </li>
                <li className="landing_page_card d-flex align-items-center mb-3 col-lg-6">
                  <div className="bg-primary iconlpge p-2 mx-3">
                    <BsWatch size={26} color="white" />
                  </div>
                  <div>
                    <p className="font-weight-bold"> Duration: </p>90 days
                  </div>
                </li>
              </ul>
              <ul className="landing_page list-unstyled d-flex mt-4">
                <li className="landing_page_card d-flex align-items-center mb-3 col-lg-6">
                  <div className="bg-danger iconlpge p-2 mx-3">
                    <BiRupee size={26} color="white" />
                  </div>
                  <div>
                    <p className="font-weight-bold">EMI start at: </p> 5,000/Monthly
                  </div>
                </li>
                <li className="landing_page_card d-flex align-items-center mb-3 col-lg-6">
                  <div className="bg-info iconlpge p-2 mx-3">
                    <BiCheck size={26} color="white" />
                  </div>
                  <div>
                    <p className='font-weight-bold'>Total fee: </p>₹ 15,000
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-md-6">
              <div className="flandingpge bg-white mx-2 p-3">
                <h3 className="h4 font-weight-bold"> Apply for Internship </h3>
                <form className="mt-3" onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label className="form-label font-weight-bold text-muted"> Full Name</label>
                    <div className="input-group input-group-merge">
                      <div className="input-icon landingpage">
                        <BsPersonCircle />
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Your Full Name"
                        name="name"
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="font-weight-bold text-muted">Email</label>
                    <div className="input-group input-group-merge">
                      <div className="input-icon landingpage">
                        <IoIosMail />
                      </div>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Your Email"
                        name="email"
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="font-weight-bold text-muted">Phone Number</label>
                    <div className="input-group input-group-merge">
                      <div className="input-icon landingpage">
                        +91
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Your Phone Number"
                        name="phone"
                        onChange={handleChange}
                        maxLength="10"
                        pattern="\d{10}"
                        onInput={(e) => e.target.value = e.target.value.replace(/\D/g, '')}
                        required
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="font-weight-bold text-muted">City</label>
                    <div className="input-group input-group-merge">
                      <div className="input-icon landingpage">
                        <FaLocationDot />
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Your City"
                        name="city"
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label font-weight-bold text-muted">Select Course</label>
                    <select className="form-control" name="course" onSelectionChange={setSelectCourse} placeholder="Select Your Interest" onChange={handleChange} aria-label="Select course">
                      {
                        Course.map((items, index) => {
                          return <option key={index} value={items.label}>{items.label}</option>
                        })
                      }
                    </select>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary w-100"
                    
                  >
                    Enroll Now
                  </button>
                </form>
                <p className="mt-3 text-center text-muted">
                  Already have an account?{' '}
                  <Link to="#" className="text-primary">
                    Login
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landingpage;
