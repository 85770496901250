import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { BsPersonCircle } from 'react-icons/bs';
import { IoIosMail } from 'react-icons/io';
import { LuPhoneCall } from 'react-icons/lu';
import { FaLaptopCode, FaLink } from "react-icons/fa";
import { FaIndianRupeeSign, FaLocationDot } from "react-icons/fa6";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import { MdAutoGraph } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
const PlanQuotaion = () => {
    const [Services, setServices] = useState([]);
    const [showCustomInput, setShowCustomInput] = useState('');
    const [modalIsOpen, setIsOpen] = useState(false);
    const location = useLocation();
    const Fetchplan = location.state?.PlanDesc;
    const navigate = useNavigate();
    const { previousLocation } = location.state || {};
    function closeModal() {
        navigate('/');
        setIsOpen(false);
    }
    const handleChange = (e) => {
        const { name, value, type } = e.target;
        if (type == "file") {
            setFormData((prevData) => ({
                ...prevData,
                [name]: e.target.files[0],
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
            if (name === "project_type" && value === "more") {
                setShowCustomInput(!showCustomInput);
            }
        }

    };

    const handleImagefile = (e) => {
        const file = e.target.files[0];
        setFormData((prevData) => ({
            ...prevData,
            file_upload_demo: file,
        }));
    };
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        mobile: "",
        project_type: "",
        plan_type: Fetchplan.plan_type || '',
        services: Fetchplan.service_title || '',
        service_charge: Fetchplan.service_charge || '',
        project_description: "",
        project_pages: '',
        targeted_location: '',
        domain_required: '',
        monthly_budget: '',
        suggested_budget: '',
        website_url: '',
        file_upload_demo: null,
    });
    // console.log(formData, 'formData')
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const res = await axios.post(
                "https://www.sdmipl.com/portal/api/add-quotation", formData
            );
            if (res.status == 200) {
                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 4000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener("mouseenter", Swal.stopTimer);
                        toast.addEventListener("mouseleave", Swal.resumeTimer);
                    },
                });
                Toast.fire({
                    icon: "success",
                    text: formData.name,
                    title: "Submitted Successfully. Thank you!",
                });
                closeModal();
                setTimeout(() => {
                    if (previousLocation) {
                        navigate(previousLocation);
                    } else {
                        navigate(-1);
                    }
                }, 30000);
            }
            else {
                console("Internal server failed");
            }
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    useEffect(() => {
        const fetchMenuData = async () => {
            try {
                const response = await fetch(
                    "https://www.sdmipl.com/portal/api/services-list"
                );
                const data = await response.json();

                const services = data.service;
                setServices(services);
                // console.log(services, 'services')
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchMenuData();
    }, []);
    return (
        <>
            <div className="container">
                <div className="col-lg-12 col-md-12 col-12 py-4">
                    <div className="serviceQform">
                        <form
                            onSubmit={handleSubmit}
                            encType="multipart/form-data"
                            method="post">
                            <fieldset className="">
                                <div className="text-muted fw-normal modalformheading">
                                    Service Request Enquiry
                                </div>
                                {/* <button
                                    onClick={closeModal}
                                    className="closebtn btn btn-outline-danger text-lg btn-lg close-button float-right">
                                    &times;
                                </button> */}
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="name" className="form-label">
                                            Name :
                                        </label>
                                        <div className="input-group input-group-merge">
                                            <div className="input-icon landingpage">
                                                <BsPersonCircle />
                                            </div>
                                            <input
                                                type="text"
                                                onChange={handleChange}
                                                id="name"
                                                name="name"
                                                placeholder="Enter Your Name"
                                                className="form-control"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="email" className="form-label">
                                            Email :
                                        </label>
                                        <div className="input-group input-group-merge">
                                            <div className="input-icon landingpage">
                                                <IoIosMail />
                                            </div>
                                            <input
                                                type="email"
                                                id="email"
                                                onChange={handleChange}
                                                placeholder="mail@gmail.com"
                                                name="email"
                                                className="form-control"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="mobile" className="form-label">
                                            Mobile :
                                        </label>
                                        <div className="input-group input-group-merge">
                                            <div className="input-icon landingpage">
                                                <LuPhoneCall />
                                            </div>
                                            <input
                                                type="text"
                                                onChange={handleChange}
                                                id="mobile"
                                                name="mobile"
                                                maxLength="10"
                                                pattern="\d{10}"
                                                onInput={(e) => e.target.value = e.target.value.replace(/\D/g, '')}
                                                className="form-control"
                                                placeholder="+91 XXXXXXXXXX"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="plan_type" className="form-label">
                                            Plan Type :
                                        </label>
                                        <div className="input-group input-group-merge">
                                            <div className="input-icon landingpage">
                                                <MdAutoGraph />
                                            </div>
                                            <input
                                                type="text"
                                                onChange={handleChange}
                                                name="plan_type"
                                                className="form-control"
                                                placeholder="plan_type"
                                                value={Fetchplan.plan_type}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="service_charge" className="form-label">
                                            Service Charges :
                                        </label>
                                        <div className="input-group input-group-merge">
                                            <div className="input-icon landingpage">
                                                <FaIndianRupeeSign />
                                            </div>
                                            <input
                                                type="text"
                                                onChange={handleChange}
                                                name="service_charge"
                                                className="form-control"
                                                placeholder="service_charge"
                                                value={Fetchplan.service_charge}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>Select a Service :</label>
                                        <div className="input-group input-group-merge">
                                            <div className="input-icon landingpage">
                                                <FaLaptopCode />
                                            </div>
                                            <input type='text' name="services" value={Fetchplan.service_title} className="form-control"
                                                placeholder="service_Title" onChange={handleChange} readOnly />
                                        </div>
                                    </div>
                                    {Fetchplan.page === 'Mobile App Development' && (
                                        <>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="project" className="form-label">
                                                    Project Type :
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <div className="input-icon landingpage">
                                                        <AiOutlineFundProjectionScreen />
                                                    </div>
                                                    <select
                                                        id="project_type"
                                                        name="project_type"
                                                        onChange={handleChange}
                                                        className="form-control"
                                                    >
                                                        <option value="" disabled selected>Project Type</option>
                                                        <option value='law'>Law App</option>
                                                        <option value='health'>Health Care App</option>
                                                        <option value="Educational">Educational App</option>
                                                        <option value="E-Commerce">E-Commerce App</option>
                                                        <option value='Quick-Commerce'>Quick-Commerce App</option>
                                                        <option value="more" >More</option>
                                                    </select>
                                                </div>
                                                {showCustomInput && (
                                                    <input
                                                        type="text"
                                                        name="project_type"
                                                        placeholder="Enter your project type"
                                                        className="form-control mt-2"
                                                        onChange={handleChange}
                                                    />
                                                )}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="hosting" className="form-label">
                                                    Domain & Hosting Purchased :
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <div className="input-icon landingpage">
                                                        <AiOutlineFundProjectionScreen />
                                                    </div>
                                                    <select
                                                        name="domain_required"
                                                        onChange={handleChange}
                                                        className="form-control"
                                                    >
                                                        <option value="" disabled selected>Domain Hosting</option>
                                                        <option value="yes">Yes</option>
                                                        <option value="no">No</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="project" className="form-label">
                                                    Number of Pages ? :
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <div className="input-icon landingpage">
                                                        <AiOutlineFundProjectionScreen />
                                                    </div>
                                                    <select
                                                        name="project_pages"
                                                        onChange={handleChange}
                                                        className="form-control"
                                                    >
                                                        <option value="" disabled selected>Select number of pages</option>
                                                        <option value='5'>5</option>
                                                        <option value="10">10</option>
                                                        <option value="15">15</option>
                                                        <option value="more">More</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {Fetchplan.page === 'Digital Marketing' && (
                                        <>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="project" className="form-label">
                                                    Target to  Location :
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <div className="input-icon landingpage">
                                                        <FaLocationDot />
                                                    </div>
                                                    <input
                                                        type="text"
                                                        onChange={handleChange}
                                                        name="targeted_location"
                                                        placeholder="Enter your Targeted Location"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="monthly_budget" className="form-label">
                                                    Monthly Budget :
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <div className="input-icon landingpage">
                                                        <FaIndianRupeeSign />
                                                    </div>
                                                    <input
                                                        type="text"
                                                        onChange={handleChange}
                                                        id="monthly_budget"
                                                        name="monthly_budget"
                                                        placeholder="Monthly_budget"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="website_url" className="form-label">
                                                    Website url :
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <div className="input-icon landingpage">
                                                        <FaLink />
                                                    </div>
                                                    <input
                                                        type="text"
                                                        onChange={handleChange}
                                                        id="project"
                                                        name="website_url"
                                                        placeholder="website_url"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {Fetchplan.page === 'Website Designing Development' && (<>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="project" className="form-label">
                                                Project Type :
                                            </label>
                                            <div className="input-group input-group-merge">
                                                <div className="input-icon landingpage">
                                                    <AiOutlineFundProjectionScreen />
                                                </div>
                                                <select
                                                    id="project_type"
                                                    name="project_type"
                                                    onChange={handleChange}
                                                    className="form-control"
                                                >
                                                    <option value="" disabled selected>Project Type</option>
                                                    <option value='law'>Law Website</option>
                                                    <option value='health'>Health Website</option>
                                                    <option value="Educational">Educational  Website</option>
                                                    <option value="E-Commerce">E-Commerce Website</option>
                                                    <option value='Quick-Commerce'>Quick-Commerce Website</option>
                                                    <option value="more" >More</option>
                                                </select>
                                            </div>
                                            {showCustomInput && (
                                                <input
                                                    type="text"
                                                    name="project_type"
                                                    placeholder="Enter your project type"
                                                    className="form-control mt-2"
                                                    onChange={handleChange}
                                                />
                                            )}
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="project" className="form-label">
                                                Number of Pages of Website ? :
                                            </label>
                                            <div className="input-group input-group-merge">
                                                <div className="input-icon landingpage">
                                                    <AiOutlineFundProjectionScreen />
                                                </div>
                                                <select
                                                    id="project_pages"
                                                    name="project_pages"
                                                    onChange={handleChange}
                                                    className="form-control"
                                                >
                                                    <option value="" disabled selected>Select number of pages</option>
                                                    <option value='5'>Under 5 Pages</option>
                                                    <option value="10">Under 10 Pages</option>
                                                    <option value="15">Under 15 Pages</option>
                                                    <option value="more">More</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="hosting" className="form-label">
                                                Domain & Hosting Purchased :
                                            </label>
                                            <div className="input-group input-group-merge">
                                                <div className="input-icon landingpage">
                                                    <AiOutlineFundProjectionScreen />
                                                </div>
                                                <select
                                                    id="hosting"
                                                    name="hosting"
                                                    onChange={handleChange}
                                                    className="form-control"
                                                >
                                                    <option value="" disabled selected>Domain Hosting</option>
                                                    <option value="yes">Yes</option>
                                                    <option value="no">No</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="suggested_budget" className="form-label">
                                                Your Budget :
                                            </label>
                                            <div className="input-group input-group-merge">
                                                <div className="input-icon landingpage">
                                                    <FaIndianRupeeSign />
                                                </div>
                                                <input
                                                    type="text"
                                                    onChange={handleChange}
                                                    id="project"
                                                    name="suggested_budget"
                                                    placeholder="Suggested Budget"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    </>
                                    )}
                                    {Fetchplan.page === 'Video Editing' && (
                                        <>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="project" className="form-label">
                                                    Editing Type :
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <div className="input-icon landingpage">
                                                        <AiOutlineFundProjectionScreen />
                                                    </div>
                                                    <select
                                                        name="project_type"
                                                        onChange={handleChange}
                                                        className="form-control"
                                                    >
                                                        <option value="" disabled selected>Editing Type</option>
                                                        <option value='Motion Graphic video editing'>Motion Graphic video editing</option>
                                                        <option value='3d video editing'>3d video editing</option>
                                                        <option value='Fiverr video editing'>Fiverr video editing</option>
                                                        <option value='Upwork video editing'>Upwork video editing</option>
                                                        <option value="more" >More</option>
                                                    </select>
                                                </div>
                                                {showCustomInput && (
                                                    <input
                                                        type="text"
                                                        name="project_type"
                                                        placeholder="Enter your project type"
                                                        className="form-control mt-2"
                                                        onChange={handleChange}
                                                    />
                                                )}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="project" className="form-label">
                                                    Number of Pages of Website ? :
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <div className="input-icon landingpage">
                                                        <AiOutlineFundProjectionScreen />
                                                    </div>
                                                    <select
                                                        name="project_pages"
                                                        onChange={handleChange}
                                                        className="form-control"
                                                    >
                                                        <option value="" disabled selected>Select Timing of video</option>
                                                        <option value='30'>30 Minute</option>
                                                        <option value="45">45 Minute</option>
                                                        <option value="60">60 Minute</option>
                                                        <option value="more">More</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="suggested_budget" className="form-label">
                                                    Your Budget :
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <div className="input-icon landingpage">
                                                        <FaIndianRupeeSign />
                                                    </div>
                                                    <input
                                                        type="text"
                                                        onChange={handleChange}
                                                        id="project"
                                                        name="suggested_budget"
                                                        placeholder="Suggested Budget"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                        </>)}
                                    <div className="form-group col-12">
                                        <label htmlFor="description" className="form-label">
                                            Project Description :
                                        </label>
                                        <textarea
                                            rows={3}
                                            type="text"
                                            id="description"
                                            onChange={handleChange}
                                            className="form-control"
                                            name="project_description"
                                            placeholder="Please provide a brief description of your project, including the main purpose and goals."
                                            defaultValue={""}
                                        />
                                    </div>
                                </div>
                                <div className="form-group col-md-6 ">
                                    <label htmlFor="file_upload_demo" className="form-label">
                                        Upload File:
                                    </label>
                                    <input
                                        type="file"
                                        id="file_upload_demo"
                                        onChange={handleImagefile}
                                        name="file_upload_demo"
                                        accept="image/*"
                                        className="form-control-file"
                                    />
                                    <p className="text-muted"><small>Any Reference (optional) : </small> </p>
                                </div>
                                <div >
                                    <button
                                        className="btn btn-outline-success float-right"
                                        type="submit">
                                        Submit
                                    </button>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PlanQuotaion; 