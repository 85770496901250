import React from 'react'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { FcOk } from "react-icons/fc";
import { FcCancel } from "react-icons/fc";
function Seopge() {
  return (
    <>
          <Helmet>
        <meta charSet="utf-8" />
        <title>
          Sion Datamatics | Contact us | Connect with Sion Datamatics | Call Now
        </title>
        <meta
          name="description"
          content={
            "Connect with Sion Datamatics for Business Investors, Media, Analysts. Post queries, jobs, alliances, partnerships, and more.Get prompt support: +91-7880109056 "
          }
        />
        <meta
          name="keywords"
          content={
            "Contact us, Visit us, Connecting us, Call now, Get prompt support: +91-7880109056, contact with us"
          }
        />
      </Helmet>
      <div className="container-fluid">
        <div className="row">
          <div className="col about-hero-section-dark">
            <h1
              className="d-flex text-center justify-content-center align-items-center about-us-hadding"
              data-aos="zoom-in">
              SEO Package
            </h1>
            <ul className="d-flex text-center justify-content-center align-items-center">
              <li>
                <Link to={"/"} className="about-us-links " aria-label="home-page">
                  Home
                </Link>
              </li>
              <li>
                <Link to="#" className="about-us-links">
                  /
                </Link>
              </li>
              <li>
                <Link to="#" className="about-us-links">
                  SEO Package
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    <div className="container">
        <h2 className='seopg_heading'>Local SEO Package</h2>
        <h3 className='seopg_heading'> In India</h3>
        <div className="d-flex justify-content-around mt-5">
        <div className='card seocrd'>
            <div className="scardhead p-3">
                <p className='text-center font-weight-bold'>Basic or Small Bussiness</p>
                <p className='text-center font-weight-light'>Seo Package</p>
            </div>
            <div className="seoprice text-center py-3">
                <p className='font-weight-bold'>12,000 INR/150 USD </p>
                <p>Monthly</p>
                <p className='text-center font-weight-light'>Exclusive of All Taxes</p>
            </div>
                <ul className='text-start px-4'>
                    <li className='spgetext'><span className='me-2'><FcCancel /></span> Google Map Integration on website</li>
                    <li className='spgetext'><span className='me-2'><FcCancel /></span> Google Map Bussiness Page Setup and Optimization</li>
                    <li className='spgetext'><span className='me-2'><FcOk /></span> Local Creation - 10</li>
                    <li className='spgetext'><span className='me-2'><FcOk /></span> Local Classifieds - 10</li>
                    <li className='spgetext'><span className='me-2'><FcOk /></span> Local News Outreach - 5</li>
                    <li className='spgetext'><span className='me-2'><FcOk /></span> Google Map Bussiness Posting - 1 Dailly</li>
                    <li className='spgetext'><span className='me-2'><FcCancel /></span>Bussiness Schema Implementation </li>
                    <p className='spgetext font-weight-bold'>CLIENT SUPPORT</p>
                    <li className='spgetext'><span className='me-2'><FcOk /></span> Email</li>
                    <li className='spgetext'><span className='me-2'><FcOk /></span> Chat</li>
                    <li className='spgetext'><span className='me-2'><FcCancel /></span> Call</li>
                </ul>
                <button
              className="sion-navigation-sec3 px-2 w-auto text-light">
           Get Start Now
            </button>
                <p className='text-center font-weight-light'>T&C Apply</p>
        </div>
        <div className='card seocrd'>
            <div className="scardhead p-3">
                <p className='text-center font-weight-bold'>Advance or Mid Size Bussiness</p>
                <p className='text-center font-weight-light'>Seo Package</p>
            </div>
            <div className="seoprice text-center py-3">
                <p className='font-weight-bold'>16,000 INR/220 USD </p>
                <p>Monthly</p>
                <p className='text-center font-weight-light'>Exclusive of All Taxes</p>
            </div>
                <ul className='text-start px-4'>
                    <li className='spgetext'><span className='me-2'><FcOk /></span> Google Map Integration on website</li>
                    <li className='spgetext'><span className='me-2'><FcOk /></span> Google Map Bussiness Page Setup and Optimization</li>
                    <li className='spgetext'><span className='me-2'><FcOk /></span> Local Creation - 10</li>
                    <li className='spgetext'><span className='me-2'><FcOk /></span> Local Classifieds - 10</li>
                    <li className='spgetext'><span className='me-2'><FcOk /></span> Local News Outreach - 5</li>
                    <li className='spgetext'><span className='me-2'><FcOk /></span> Google Map Bussiness Posting - 1 Dailly</li>
                    <li className='spgetext'><span className='me-2'><FcOk /></span> Bussiness Schema Implementation </li>
                    <p className='spgetext font-weight-bold'>CLIENT SUPPORT</p>
                    <li className='spgetext'><span className='me-2'><FcOk /></span> Email</li>
                    <li className='spgetext'><span className='me-2'><FcOk /></span> Chat</li>
                    <li className='spgetext'><span className='me-2'><FcOk /></span> Call</li>
                </ul>
                <button
              className="sion-navigation-sec3 px-2 w-auto text-light">
           Get Start Now
            </button>
                <p className='text-center font-weight-light'>T&C Apply</p>
        </div>
        <div className='card seocrd'>
            <div className="scardhead p-3">
                <p className='text-center font-weight-bold'>Enterprise or E-Commerce Bussiness</p>
                <p className='text-center font-weight-light'>Seo Package</p>
            </div>
            <div className="seoprice text-center py-3">
                <p className='font-weight-bold'>24,000 INR/350 USD </p>
                <p>Monthly</p>
                <p className='text-center font-weight-light'>Exclusive of All Taxes</p>
            </div>
                <ul className='text-start px-4'>
                    <li className='spgetext'><span className='me-2'><FcOk /></span> Google Map Integration on website</li>
                    <li className='spgetext'><span className='me-2'><FcOk /></span> Google Map Bussiness Page Setup and Optimization</li>
                    <li className='spgetext'><span className='me-2'><FcOk /></span> Local Creation - 10</li>
                    <li className='spgetext'><span className='me-2'><FcOk /></span> Local Classifieds - 10</li>
                    <li className='spgetext'><span className='me-2'><FcOk /></span> Local News Outreach - 5</li>
                    <li className='spgetext'><span className='me-2'><FcOk /></span> Google Map Bussiness Posting - 1 Dailly</li>
                    <li className='spgetext'><span className='me-2'><FcOk /></span> Bussiness Schema Implementation </li>
                    <p className='spgetext font-weight-bold'>CLIENT SUPPORT</p>
                    <li className='spgetext'><span className='me-2'><FcOk /></span> Email</li>
                    <li className='spgetext'><span className='me-2'><FcOk /></span> Chat</li>
                    <li className='spgetext'><span className='me-2'><FcOk /></span> Call</li>
                </ul>
                <button
              className="sion-navigation-sec3 px-4 w-auto text-light">
           Get Start Now
            </button>
                <p className='text-center font-weight-light'>T&C Apply</p>
        </div>
        </div>
    </div>
    </>
  )
};

export default Seopge;