import React, { useState, useEffect, useRef } from 'react'
import JoditEditor from 'jodit-react';

import { Link } from 'react-router-dom';

const Quotation = () => {
  const editor = useRef(null);
  const [content, setContent] = useState('');
  const [images, setImages] = useState([]);
  const [file, setFile] = useState(null);
  const [selectedValue, setSelectedValue] = useState(1);
  const [PagesValue,setPagesValue] = useState(0)
  const [Totalpages, setTotalpages] = useState(1);
  const [WebPrice, setWebPrice] = useState(0);
  const [Domain, setDomain] = useState(0);
  const [Hosting, setHosting] = useState(0);
  const [Taxes, setTaxes] = useState(0);
  const [brandName, setbrandName] = useState("____________");
  const [GrandTotal, setGrandTotal] = useState(0)
  const [price , setprice] = useState(1)
  console.log(file)
  useEffect(() => {
    const finaldata = parseInt(selectedValue) * parseInt(PagesValue);
    setprice(finaldata);
    const websiteprice = finaldata * Totalpages;
    setWebPrice(websiteprice)
    const webTax = (parseInt(websiteprice) * 18) / 100;
    const domainTax = (parseInt(Domain) * 18) / 100;
    const hostingTax = (parseInt(Hosting) * 18) / 100;
    setTaxes(parseInt(webTax) + parseInt(domainTax) + parseInt(hostingTax))
    setGrandTotal(parseInt(WebPrice) + parseInt(Domain) + parseInt(Hosting) + parseInt(Taxes))
  }, [Totalpages, selectedValue, WebPrice, Domain, Hosting, Taxes, GrandTotal ,PagesValue]);

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  }
  const handlePageChange = (event) => {
    setPagesValue(event.target.value);
  }
  const handleDomainChange = (event) => {
    setDomain(event.target.value);
  }
  const handleHostingChange = (event) => {
    setHosting(event.target.value);
  }

  const handleImageChange = (e) => {
    const files = e.target.files;

    const totalSize = Array.from(files).reduce((acc, file) => acc + file.size, 0);
    const maxSize = 10 * 1024 * 1024; // 10 MB

    if (totalSize > maxSize) {
      alert('Total file size exceeds 10 MB. Please select fewer or smaller files.');
      return;
    }

    if (images.length + files.length > 3) {
      alert('You can upload a maximum of 3 images.');
      return;
    }

    const newImages = Array.from(files).map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));

    setImages((prevImages) => [...prevImages, ...newImages]);
  };

  const handleRemoveImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };
  // upload pdf to backend 




  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile && selectedFile.type === 'application/pdf') {
      // Read the file and convert it to a data URL
      const reader = new FileReader();
      reader.onloadend = () => {
        setFile(selectedFile);

      };
      reader.readAsDataURL(selectedFile);
    } else {
      // Handle invalid file type
      alert('Please select a valid PDF file.');
      setFile(null);
    }
  };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     window.print();
// };

  return (
    <>
      <section className="main-heading">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="text-center mt-5">Website Budget Calculator</h1>
            </div>
          </div>
        </div>
      </section>

      <div className="container-fluid budget pb-5">
        <div className="row">
          <div className="col-lg-8">
            <div className="services mt-5 ">
              <div className="one p-3">
                <div className="row ">
                  <div className="col-lg-6 col pl-0 p-5">
                    <h2 className="mb-3 ">Select The Type </h2>
                    {/* <p className="para">Select The Type as per your Requirements</p> */}

                    <div className="dropd">
                      <div className="form-check  ">
                        <input
                          className="form-check-input mt-2"
                          type="radio"
                          name="group0"
                          id="exampleRadios1"
                          value={2000}
                          onChange={handleRadioChange}

                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios1"
                        >
                          <span className="hh6 text-center"> E-commerce</span>
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input mt-2"
                          type="radio"
                          name="group0"
                          id="exampleRadios1"
                          value={500}
                          onChange={handleRadioChange}

                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios1"
                        >
                          <span className="hh66"> Bloging </span>
                        </label>

                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input mt-2"
                          type="radio"
                          name="group0"
                          id="exampleRadios1"
                          value={1500}
                          onChange={handleRadioChange}

                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios1"
                        >
                          <span className="hh6"> Educational</span>
                        </label>

                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input mt-2"
                          type="radio"
                          name="group0"
                          id="exampleRadios1"
                          value={750}
                          onChange={handleRadioChange}

                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios1"
                        >
                          <span className="hh66"> Portfolio</span>
                        </label>

                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input mt-2"
                          type="radio"
                          name="group0"
                          id="exampleRadios1"
                          value={850}
                          onChange={handleRadioChange}

                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios1"
                        >
                          <span className="hh6"> Static/Informative website</span>
                        </label>

                      </div>

                    </div>
                    <div className="option mt-3">
                    <p className='font-bold'>After Catogery Selection Chose Pages Type</p>
                      <div className="drop">
                     
                        <input
                          type="radio"
                          name="group2"
                          value={1}
                          onChange={handlePageChange}
                        />
                        <span className="pl-2 pr-2 font-weight-normal">Static </span>

                        <input type="radio" name="group2" value={2} onChange={handlePageChange} />
                        <span className="font-weight-normal pl-2 pr-2">Dynamic </span>
                        <input type="radio" name="group2" value={3} onChange={handlePageChange} />
                        <span className="font-weight-normal pl-2 pr-2">All </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col d-flex justify-content-center align-items-center ">
                    <h2 className="special">Select no. of pages</h2>
                    <div className="form-group">
                      <input
                        type="Number"
                        className="form-control input_num text-center"
                        id="exampleInputPassword1"
                        value={Totalpages}
                        onChange={(e) => setTotalpages(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="services mt-5">
              <div className="one p-3">
                <div className="row ">
                  <div className="col-lg-6 p-5 col">
                    <h2 className="mb-3">Do you Also want Domain?</h2>
                    {/* <p className="para">Also want Domain</p> */}
                    <div className="drop mt-4 ">
                      <input
                        type="radio"
                        name="group1"
                        defaultValue={1}
                        data-toggle="modal"
                        data-target="#exampleModal"
                      />
                      <span className="ml-2 mr-2 hh6">If Yes </span>
                      {/* modal */}
                      {/* Button trigger modal */}
                      {/* Modal */}
                      <div
                        className="modal fade"
                        id="exampleModal"
                        tabIndex={-1}
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel">
                                Choose one From Below
                              </h5>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="d-flex">
                                <h6 className="enter_modal">
                                  Enter your brand name{" "}
                                </h6>
                                <input
                                  type="text"
                                  className="form-control input_num"
                                  id="exampleInputPassword1"
                                  onChange={(e) => setbrandName(e.target.value)}
                                />
                              </div>
                              <div id="radiomain">
                                <div className="cardd d-flex pb-2">
                                  <img src="images/dom2.png" alt='imagedom' title='/' />
                                  <p className="mx-auto my-auto">
                                    {" "}
                                    www.{brandName}.com{" "}
                                  </p>
                                  <div className="form-check  my-auto">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      onChange={handleDomainChange}
                                      name="exampleRadios"
                                      id="radio2"
                                      value={100}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="exampleRadios1"
                                    ></label>
                                  </div>
                                </div>
                                <div className="cardd d-flex pb-2">
                                  <img src="images/dom1.png" alt='imagedom' title='/' />
                                  <p className="mx-auto my-auto">
                                    {" "}
                                    www.{brandName}.in{" "}
                                  </p>
                                  <div className="form-check  my-auto">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      onChange={handleDomainChange}
                                      name="exampleRadios"
                                      id="radio2"
                                      value={200}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="exampleRadios1"
                                    ></label>
                                  </div>
                                </div>
                                <div className="cardd d-flex pb-2">
                                  <img src="images/dom3.png" alt='imagedom' title='/' />
                                  <p className="mx-auto my-auto">
                                    {" "}
                                    www.{brandName}.org{" "}
                                  </p>
                                  <div className="form-check  my-auto">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      onChange={handleDomainChange}
                                      name="exampleRadios"
                                      id="radio2"
                                      value={300}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="exampleRadios1"
                                    ></label>
                                  </div>
                                </div>
                                <div className="cardd d-flex pb-2">
                                  <img src="images/dom5.png" alt='imagedom' title='/' />
                                  <p className="mx-auto my-auto">
                                    {" "}
                                    www.{brandName}.edu{" "}
                                  </p>
                                  <div className="form-check  my-auto">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      onChange={handleDomainChange}
                                      name="exampleRadios"
                                      id="radio2"
                                      value={400}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="exampleRadios1"
                                    ></label>
                                  </div>
                                </div>
                                <div className="cardd d-flex pb-2">
                                  <img src="images/dom4.png" alt='imagedom' title='/' />
                                  <p className="mx-auto my-auto">
                                    {" "}
                                    www.{brandName}.gov{" "}
                                  </p>
                                  <div className="form-check  my-auto">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      onChange={handleDomainChange}
                                      name="exampleRadios"
                                      id="radio2"
                                      value={500}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="exampleRadios1"
                                    ></label>
                                  </div>
                                </div>
                              </div>

                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btnn"
                                data-dismiss="modal"
                                onChange={handleDomainChange}
                              >
                                Close
                              </button>
                              <button
                                type="submit"
                                className="btn "
                                data-dismiss="modal"
                              >
                                Save changes
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* modl */}
                      <input type="radio" name="group1" value={0} onChange={handleDomainChange} />
                      <span className="hh66 pl-2">Else No</span>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 img1 my-auto ">
                    <img
                      src="images/domain_thumbnail_2.png"
                      alt=""
                      className=" img-fluid mx-5"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="services mt-5">
              <div className="one p-3">
                <div className="row ">
                  <div className="col-lg-6 p-5 col">
                    <h2 className="mb-3">Do you Also want Hosting?</h2>
                    {/* <p className="para">Required Hosting</p> */}
                    <div className="drop mt-4 ">
                      <input
                        type="radio"
                        name="group2"
                        defaultValue={1}
                        data-toggle="modal"
                        data-target="#exampleModal1"
                      />
                      <span className=" ml-2 mr-2 hh6">If Yes</span>
                      {/* Button trigger modal */}
                      {/* Modal */}
                      <div
                        className="modal fade"
                        id="exampleModal1"
                        tabIndex={-1}
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel">
                                Choose one From Below
                              </h5>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <div id="radiomain1">
                                <div className="cardd d-flex pb-2">
                                  <img src="./images/host1.png" alt='logo' />
                                  <p className="mx-auto my-auto">
                                    250GB / Year for Rupees 2500{" "}
                                  </p>
                                  <div className="form-check  my-auto">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="modal-2"
                                      id="radio3"
                                      onChange={handleHostingChange}
                                      value={2500}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="exampleRadios1"
                                    ></label>
                                  </div>
                                </div>
                                <div className="cardd d-flex">
                                  <img src="images/host2.png" alt='imagedom' title='/' />
                                  <p className="mx-auto my-auto">
                                    500GB / Year for Rupees 5000{" "}
                                  </p>
                                  <div className="form-check my-auto">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="modal-2"
                                      id="radio3"
                                      onChange={handleHostingChange}
                                      value={5000}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="exampleRadios1"
                                    ></label>
                                  </div>
                                </div>
                              </div>
                              {/* card */}
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btnn"
                                data-dismiss="modal"
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                className="btn "
                                id="save"
                                data-dismiss="modal"
                              >
                                Save changes
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <input type="radio" name="group2" value={0} onChange={handleRadioChange} />
                      <span className="hh66 pl-2">Else No</span>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 img1 my-auto">
                    <img
                      src="images/host_thumbnail1.png"
                      alt=""
                      className="img-fluid mx-5"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="services mt-5">
              <div className="one">
                <div className="row ">
                  <div className="col-lg-6 p-5 col-sm-6">
                    <h2 className="mb-3 text-center">Documentation</h2>

                    <div className="form-group mt-5">
                      <label htmlFor="exampleFormControlFile1" className="doc mb-5">
                        Upload your logo(naximum 3 Images)
                      </label>
                      <input
                        type="file"
                        className="form-control-file"
                        id="exampleFormControlFile1"
                        accept="png, jpeg, jpg"
                        onChange={handleImageChange}
                        multiple
                      />
                    </div>
                    <div className="image-row">
                      {images.map((image, index) => (
                        <div key={index} className="image-preview-container">
                          <img src={image.preview} alt={`Preview ${index}`} className="image-preview" />
                          <span onClick={() => handleRemoveImage(index)} className="fa fa-trash text-danger ml-1">
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="col-lg-6 col-sm-6 p-5">
                    <div className="form-group mt-5">
                      <label htmlFor="exampleFormControlFile1" className="doc mb-5">
                        Upload your Document
                      </label>
                      <input
                        type="file"
                        className="form-control-file"
                        id="exampleFormControlFile1"
                        accept="application/pdf"
                        onChange={handleFileChange}
                      />
                    </div>


                  </div>
                  {/* text editor */}
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <JoditEditor
                          ref={editor}
                          value={content}
                          //config={config}
                          tabIndex={1} // tabIndex of textarea
                          onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                          onChange={newContent => setContent(newContent)}
                        />

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
          <div className="col-lg-4">
            <div className="second-side mt-5 ">
              <div className="inner">
                <h4 className="head "> Order Summary</h4>
                <div className="total">
                  <div className="inr-total d-flex justify-content-space-around my-auto">
                    <h6>Website per page</h6>
                    <p>
                    <span className='mr-2' id="pages">{price !== null && price !== undefined && price !== isNaN ? price : 0}</span>
 <i className="fa-solid fa-xmark text-dark" />
                      <span className='ml-3' id="fix">{Totalpages}</span>
                    </p>
                    <p>
                      <i className="fa-solid fa-indian-rupee-sign" />
                      <span id="result">{WebPrice}.00</span>
                    </p>
                  </div>
                  <div className=" d-flex inr-total justify-content-space-around my-auto">
                    <h6>Domain Price</h6>
                    <p>
                      <i className="fa-solid fa-indian-rupee-sign" />
                      <span id="domain">{Domain}.00</span>
                    </p>
                  </div>
                  <div className=" inr-total d-flex justify-content-space-around my-auto">
                    <h6>Hosting Price</h6>
                    <p>
                      <i className="fa-solid fa-indian-rupee-sign" />
                      <span id="hostingg">{Hosting}.00</span>
                    </p>
                  </div>
                  <div className=" inr-total  d-flex justify-content-space-around my-auto">
                    <span><h6 className='d-flex'>Taxes </h6> <p>Standard (18%) </p></span>
                    <p>
                      <i className="fa-solid fa-indian-rupee-sign" />
                      <span id="tax">{Taxes}.00</span>
                    </p>
                  </div>
                  <div className=" inr-total grand d-flex justify-content-space-around my-auto">
                    <h5>Grand Total</h5>
                    <p>
                      <i className="fa-solid fa-indian-rupee-sign" />
                      <span id="gt">{GrandTotal}.00</span>
                    </p>
                  </div>
                </div>
                <div className="check">
                  <div className="input-group mb-3">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck1"
                      />
                      <label className="form-check-label" htmlFor="exampleCheck1">
                        <p>
                          accept<span style={{ color: "#009CA6" }}>Terms</span>
                        </p>
                      </label>
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn btnn" id="submit">
                  Submit
                </button>
                <button type="button" className="btn btn-primary">
                  <Link to="https://www.sdmipl.com/" className="text-light" style={{ textDecoration: 'none' }}>Go to the HomePage</Link>
                </button>
              </div>
            </div>
          </div>
        
        </div>
      </div>
    </>

  )
}

export default Quotation
