import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

function Blog() {

  const [Blog, setBlogs] = useState('');
  const linkurl = useLocation();
  console.log("link url",linkurl)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://www.sdmipl.com/portal/api/blogs`);
        setBlogs(response.data.blogs);
      } catch (error) {

        console.error("data", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div className='w-full md:w-[85%]  mx-auto'>
  <Helmet>
        <title>
          Sion Datamatics | Our Blogs | Connect with Sion Datamatics | Call Now
        </title>
        <meta
          name="description"
          content={
            "Connect with Sion Datamatics for Business Investors, Media, Analysts. Post queries, jobs, alliances, partnerships, and more.Get prompt support: +91-7880109056 "
          }
        />
        <meta
          name="author"
          content={
            "Sion Datamatics"
          }
        />
        <meta
          name="keywords"
          content={
            "Our Blogs, Contact us,  Visit us, Connecting us, Call now, Get prompt support: +91-7880109056, contact with us"
          }
        />
        <meta property="og:title" content="Sion Datamatics | support  contact Company in Indore India" />
        <meta property="og:description"
          content="Sion Datamatics: Blog Page, Blogs, crafting innovative solutions for businesses. Collaborate with us to shape new strategies for growth." />
        <meta name="twitter:title" content="Sion Datamatics India Pvt. Ltd." />
        <meta name="twitter:description"
          content="Sion Datamatics: Indore's premier software company, crafting innovative solutions for businesses. Collaborate with us to shape new strategies for growth." />
           <Link rel="canonical" to={linkurl.pathname || ""} />
      </Helmet>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col about-hero-section-dark">
              <h1 className="d-flex text-center justify-content-center align-items-center about-us-hadding"
                data-aos="zoom-in">Blogs</h1>
              <ul className="d-flex text-center justify-content-center align-items-center">
                <li><Link to="/" aria-label='home navigate' className="about-us-links">Our </Link></li>
                <li><Link to="#" className="about-us-links"> / </Link></li>
                <li><Link to="#" className="about-us-links">Blog</Link></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container my-5">
            <h2 className='text-center font-medium text-muted col-12'>Welcome to the Sion Datamatics India Pvt.Ltd </h2>
            <h3 className='col-12 text-center text-muted'>Blogs!</h3>
          <div className="row gap-2 ">
            {Blog && Blog.map((blog) => (
              <div className="col-lg-4 col-md-6 col-12 my-3  " key={blog.id}>
                <div className="blog-dis">
                  <div className="blog-img">
                    <img
                      src={`${blog ? blog.image : 'https://edumen.codelayers.net/images/blog/02.png'}`}
                      alt={blog.title} title={blog.title}
                    />
                  </div>  
                  <Link aria-label={blog.title} data-toggle="tooltip" data-placement="left" title={blog.title} className="flex gap-2 blog-btn btn" to={`/blog/${blog.slug}`}>
                    <div className="blog-details">
                      <span className="d-flex align-items-center text-white date">
                        <FaRegCalendarAlt className='mx-3 my-1' />{new Date(blog.updated_at).toLocaleDateString('en-GB')}
                      </span>
                      <h4 className='text-left text-dark'>{blog.title}</h4>
                      <p className='text-left item-start text-dark'>{blog.meta_description}</p>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Blog;
