import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";

import Slider from "./slider/slider";
import Blogs_slider from "./slider/blogs_slider";
const Home = () => {
  const [data, setData] = useState("");
  const [Testimonial, setTestimonials] = useState([]);
  const [portfolio, setPort] = useState([]);
  const [Blog, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(20);
  const linkurl = useLocation();
  useEffect(() => {
    const interval = setInterval(() => {
      if (count < 100) {
        setCount((prevCount) => prevCount + 1);
      } else {
        clearInterval(interval);
      }
    }, 20); // Adjust the speed of typing effect here

    return () => clearInterval(interval);
  }, [count]);

  //

  const owlOptions = {
    autoplay: true,
    rewind: true,
    margin: 16,
    autoplayHoverPause: true,
    dots: false,
    responsiveClass: true,
    autoHeight: true,
    autoplayTimeout: 7000,
    smartSpeed: 2800,
    nav: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1024: {
        items: 2,
      },
      1366: {
        items: 2,
      },
    },
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://code.jquery.com/jquery-3.6.4.min.js";
    script.async = true;

    script.onload = () => {
      if (window.$) {
        const owlNav = window.$(".owl-nav.disabled");

        if (owlNav.length > 0) {
          owlNav.find(".owl-prev, .owl-next").removeAttr("role");
        }
      }
    };

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [Blog, Testimonial]);
  const blog = {
    autoplay: true,
    rewind: true,
    transitionDuration: 400,
    margin: 18,
    dots: false,
    autoplayHoverPause: true,
    responsiveClass: true,
    autoHeight: true,
    autoplayTimeout: 6000,
    smartSpeed: 3000,
    nav: false,

    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1024: {
        items: 3,
      },
      1366: {
        items: 3,
      },
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://www.sdmipl.com/portal/api/single-post/home"
        );
        const data = await response.json();
        setData(data);

        const testimonialResponse = await fetch(
          "https://www.sdmipl.com/portal/api/testimonial-view"
        );
        const testimonialData = await testimonialResponse.json();
        setTestimonials(testimonialData.records);

        const portResponse = await fetch(
          "https://www.sdmipl.com/portal/api/portfolio-view"
        );
        const portData = await portResponse.json();
        setPort(portData.records);

        const blogResponse = await fetch(
          "https://www.sdmipl.com/portal/api/blogs"
        );
        const blogData = await blogResponse.json();
        setBlogs(blogData.blogs);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  if (loading && !data) {
    return (
      <div className="lodindingcontner">
        <div id="progressbar">
          <span id="loading"></span>
          <div id="load">Loading...</div>
        </div>
      </div>
    );
  }

  const { meta_title, meta_description, meta_keyword, canonicalUrl, content } =
  data?.post || {};

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title name="title">
          {meta_title || "Best IT Company in Indore Madhya Pradesh India"}
        </title>
        <meta
          name="description"
          content={
            meta_description || "Sion Datamatics leading software development company in Indore, delivering innovative solutions for businesses"
          }
        />
        <meta
          name="keywords"
          content={
            meta_keyword || "UI/ UX Designing, Logo Designing , Content Writing ,Search Engine Optimization,Video Editing , 2D/3D Animation ,  Graphic Designing , Digital Marketing , Mobile App Development "
          }
        />
        <Link rel="canonical" to={linkurl.pathname || ""} />
      </Helmet>
      <div dangerouslySetInnerHTML={{ __html: content }} />

      {/* Hear From Our Customer */}
      {/* <div className="web-customer-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="web-customer-sec-top" data-aos="zoom-in">
                <h4 className=" text-center text-light web-customer-sec-hadding h3">
                  Hear From Our Customer
                </h4>
                <p className="web-customer-sec-para">
                  The Voice of the People
                </p>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="web-customer-sec-bottom">
                <OwlCarousel className="owl-theme" {...owlOptions}>
                  {Testimonial &&
                    Testimonial.map((data, i) => (
                      <div className="item" key={i}>
                        <div className="web-sion-customer-sec gap-2">
                          <div className="web-sion-customer-sec1 justify-content-between">
                            <div className="web-sion-customer-left">
                              <p className="web-sion-customer-name">
                                {data.name}
                              </p>
                              <p className="web-sion-customer-real">
                                {data.designation}
                              </p>
                              <p className="web-sion-customer-rating">
                                <span className="fa fa-star checked" />
                                <span className="fa fa-star checked" />
                                <span className="fa fa-star checked" />
                                <span className="fa fa-star" />
                                <span className="fa fa-star" />
                              </p>
                            </div>
                            <div className="web-sion-customer-right ">
                              <img
                                src={`${data.image
                                    ? data.image
                                    : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0m5Cy4lXCbuyG54L0vuo3i5-ALavHe9KmhWA_wDM&s"
                                  }`}
                                alt="Sion_hero"
                                className="customer-real"
                              />
                            </div>
                          </div>
                          <div className="web-sion-customer-sec2 bg-transparent">
                            <p className="web-sion-customer-comment text-justify mr-3">
                              {data.testimony}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                </OwlCarousel>
              
              </div>
            </div>
          
          </div>
        </div>
      </div> */}
        <Slider />
      {/* Hear From Our Customer End */}

      {/*tabs*/}
      <section className="recent-sec">
        <div className="container">
          <div className="row">
            <div className="col-12" data-aos="zoom-in">
              <h5 className="service-tabs-hadding">Project Portfoliao</h5>
              {/* <p className="service-tabs-paragraph">Sion DataMatics India PVT. LTD. recentaly working on</p> */}
            </div>
          </div>

          <div className="row">
            <div className="col-12 food-container">
              <div className="row align-items-center">
                {portfolio.slice(0, 20).map((item, i) => (
                  <div className="col-lg-4  gap-2 mb-4" key={i}>
                    <div className="img-container object-cover cover-fill p-0 m-0">
                      <img
                        src={`${item.image ? item.image : "./assets/images/work1.png"
                          }`}
                        className=" img-thumbnail image_project"
                        alt={item.title}
                        title={item.title}
                      />
                      <div className="img-content">
                        <h5 className="text-white ">{item.title}</h5>
                        <Link
                          to={`${item.url}`}
                          target="_blank"
                          className="btn btn-danger text-white mt-2"
                          aria-label="project details">
                          view project
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*tabs End*/}

      {/* Blog Seection */}
      {/* <section className="sion-blog-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sion-blog-sec-top" data-aos="zoom-in">
                <h5 className="text-center sion-blog-sec-hadding h3 l">
                  Our Blogs
                </h5>
                <p className="sion-blog-sec-pg">
                  "Breaking Down Complex Tech Topics for Your Understanding."
                </p>
              </div>
            </div>

            <div className="col-12">
              {Blog && Blog.length > 0 && (
                <OwlCarousel className="owl-theme" {...blog}>
                  {Blog.map((item) => (
                    <div className="gap-4 mx-2 " key={item.id}>
                      <div className="event-item">
                        <div className="thumb">
                          <img
                            src={`${item
                                ? item.image
                                : "https://edumen.codelayers.net/images/blog/02.png"
                              }`}
                            alt={item.title}
                            title={item.title}
                          />
                        </div>

                        <Link
                          className="linktagcolor"
                          to={`blog/${item.slug}`}
                          aria-label={item.title}>
                          <div className="event-content">
                            <div className="date">
                              {item.updated_at.slice(0, 10)}
                            </div>

                            <h5>{item.title} </h5>
                            <p>{item.meta_description}</p>
                            <div className="time">
                              {new Date(item.created_at).toLocaleDateString(
                                "en-GB"
                              )}
                              <i className="fa-solid fa-arrow-right-long" />{" "}
                              <strong> India</strong>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
              )}
            </div>
          </div>
        </div>
      </section> */}
      {/* Blog Seection End*/}
      <Blogs_slider />
    </>
  );
};

export default Home;
