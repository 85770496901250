import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import errimg from '../../assets/images/404.jpg'

const HandleError = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/');
    }, 5000);
    return () => clearTimeout(timer);
  }, [navigate]);
  return (
    <>
      <Helmet>
        <title>404 | Page Not Found - Sion DataMAtics  India Pvt Ltd</title>
        <meta name="description" content="Oops! It seems like the page you're looking for doesn't exist. Explore more about Your Company and find what you need." />
        <meta name="keywords" content="Your Company, page not found, 404 error, website not found" />
        <Link rel="canonical" to="/" />
      </Helmet>
      <div className="errorhandel d-flex align-items-center justify-content-center" >
        <div className="text-center">
        <img class="errorHandel" src={errimg} height="255" width="800" />
          {/* <h1 className="display-1 fw-bold p-sm-2 text-warning">404</h1> */}
          <p className="fs-3 "> <span className="text-danger font-weight-bold fs-5">Opps!</span> Page not found.</p>
          <p className="lead fw-semibold" color='blue'>
            The page you're looking for doesn't exist.
          </p>
          <button type="button" className='btn text-white btn-outline-danger my-3' name="button"><Link to='/' className='text-white'> Return Home </Link></button>
        </div>
      </div>
    </>
  )
}

export default HandleError;