import React, { useEffect, useState } from 'react';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { Link, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';

const BlogPage = () => {
  const { slug } = useParams();
  const [Blog, setBlogs] = useState({});
  const [Allblog, setAllblog] = useState([]);
  const [loading, setLoading] = useState(true);
  const linkurl = useLocation();
  const [data, setData] = useState({
    post: {
      meta_title: "",
      meta_description: "",
      meta_keyword: "",
      canonicalUrl: "",
      content: ""
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://www.sdmipl.com/portal/api/single-blog/${slug}`);
        setBlogs(response.data.post);

        const responseAll = await axios.get(`https://www.sdmipl.com/portal/api/blogs`);
        setAllblog(responseAll.data.blogs);
        
        setData(response.data); // Update data state with fetched data
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error("Data fetching error:", error);
      }
    };
    fetchData();
  }, [slug]);

  const { meta_title, meta_description, meta_keyword, canonicalUrl, content } = data.post;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title name="title">
          {meta_title || "Best IT Company in Indore Madhya Pradesh India"}
        </title>
        <meta
          name="description"
          content={
            meta_description || "Sion Datamatics leading software development company in Indore, delivering innovative solutions for businesses"
          }
        />
        <meta
          name="keywords"
          content={
            meta_keyword || "Blog Page,  Blogs , UI/ UX Designing, Logo Designing , Content Writing ,Search Engine Optimization,Video Editing , 2D/3D Animation ,  Graphic Designing , Digital Marketing , Mobile App Development "
          }
        />
        <meta property="og:title" content="Sion Datamatics | support  contact Company in Indore India" />
        <meta property="og:description"
          content="Sion Datamatics: Blog Page, Blogs, crafting innovative solutions for businesses. Collaborate with us to shape new strategies for growth." />
        <meta name="twitter:title" content="Sion Datamatics India Pvt. Ltd." />
        <meta name="twitter:description"
          content="Sion Datamatics: Indore's premier software company, crafting innovative solutions for businesses. Collaborate with us to shape new strategies for growth." />
        <Link rel="canonical" to={linkurl.pathname || ""} />
      </Helmet>
      {Object.keys(data.post).length > 0 && ( 
        <section className="py-5 blog-details-sec">
          <div className="container-fluid">
            <div className="row p-0 m-0">
              <div className="col-lg-9">
                <div className="blog-dis">
                  <div dangerouslySetInnerHTML={{ __html: Blog.content }} />
                </div>
              </div>
              <div className="col-lg-3 col-md-4">
                <div className='r_blog'>
                  <div className="search-s">
                    {/* <div className="form-group d-flex">
                      <input
                        type="text"
                        className="form-control"
                        id="search-s"
                        placeholder="Search Courses"
                      />
                      <button className="s-btn">
                        <FaSearch className='mx-auto itmes-center' />
                      </button>
                    </div> */}
                  </div>

                  <div className="r-post">
                    <h4>Recent Blog</h4>
                    {Allblog && Allblog.map((item, i) => (
                      <div className="py-3 post-dis" key={i}>
                        <img
                          src={item.image || "https://www.ecologytheme.com/theme/eduwise/images/blog/side_blog_2.jpg"}
                          alt={item.title.slice(0, 15)}
                        />
                        <div className="post-c">
                          <Link className="linktagcolor" aria-label='single page details' to={`https://www.sdmipl.com/blog/${item.slug}`}>
                            <h5 className='blogspecial'>{item.title}</h5>
                          </Link>
                          <span className="flex gap-2 date">
                            <FaRegCalendarAlt className='mt-1 text-[#052963]' /> {new Date(item.updated_at).toLocaleDateString('en-GB')}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default BlogPage;
