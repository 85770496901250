import React from 'react'
import { Link } from 'react-router-dom';
const Privacy = () => {
  return (
  <div>

  
  <div className="container-fluid">
    <div className="row">
      <div className="col about-hero-section-dark">
        <h5 className="d-flex text-left justify-content-center align-items-center about-us-hadding" data-aos="zoom-in">
          Privacy Policy</h5>
        <ul className="d-flex text-left justify-content-center align-items-center">
          <li><Link to="/home" className="about-us-links">Home </Link></li>
          <li><Link to="#" className="about-us-links"> / </Link></li>
          <li><Link to="/privacy-policy" className="about-us-links">Privacy Policy</Link></li>
        </ul>
      </div>
    </div>
  </div>

  <section className="privacy-head1">
    <div className="container">
      <div className="row">
        <div className="col-lg-12 pt-4">
          <h5 className=" text-left f-4 term-hadding term-h-top">Privacy Policy</h5>
          <p className="pt-2 text-justify f-3 term-para">
            At Sion Datamatics (India) Private Limited, we are committed to protecting and respecting your
            privacy. This Privacy Policy sets out the basis on which any personal data we collect from you or that
            you provide to us will be processed by us. Please read the following carefully to understand our
            practices regarding your personal data and how we will treat it.
          </p>
          <h5 className="pt-5 text-left f-4 term-hadding">We may collect and process the following data about you:</h5>
          <p className="pt-2  text-justify f-3 term-para">
            information that you provide by filling in forms on our website or corresponding with us by phone,
            email, or otherwise.
            Details of your visits to our website and the resources that you access.
            Use of Information
          </p>
          <h5 className="pt-5 text-left f-4 term-hadding">We use information held about you in the following ways:</h5>
          <p className="pt-2  text-justify f-3 term-para">
            To provide you with information or services that you request from us or which we feel may interest
            you, where you have consented to be contacted for such purposes.
            To carry out our obligations arising from any contracts entered into between you and us.
            To notify you about changes to our service.
            Disclosure of Your Information.
          </p>
          <div className="highlight">
            <p>We may disclose your personal information to any member of our group, which means our subsidiaries,
              our ultimate holding company, and its subsidiaries.</p>
          </div>
          <h5 className="pt-5 text-left f-4 term-hadding">Data Security</h5>
          <p className="pt-2  text-justify f-3 mb-5 term-para">
            We have put in place appropriate security measures to prevent your personal data from being
            accidentally lost, used, or accessed in an unauthorized way, altered, or disclosed.
          </p>
         
          <h5 className=" text-left f-4 term-hadding">Your Rights</h5>
          <p className="pt-2  text-justify f-3 mb-5 term-para">
            You have the right to ask us not to process your personal data for marketing purposes. You can
            exercise your right to prevent such processing by checking certain boxes on the forms we use to
            collect your data.
          </p>
        </div>
      </div>
    </div>
  </section>
</div>


  )
}

export default Privacy;